import React from 'react';

import './header.scss';
import ResponsiveBoxHead from '../../../../../components/UI/ResponsiveBoxHead';
import LinkButton from '../../../../../components/common/buttons/Link/Link';

const Header = ({ data, ...rest }) => {
  return (
    <ResponsiveBoxHead {...rest}>
      <div className="ai_base_header">
        <div className="content">
          <h1 dangerouslySetInnerHTML={{ __html: data.title }} />
          <p dangerouslySetInnerHTML={{ __html: data.description }} />
        </div>
        <LinkButton to={'/contact'}>Get in touch</LinkButton>
      </div>
    </ResponsiveBoxHead>
  );
};

export default Header;
