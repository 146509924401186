import React from 'react';
import { ActiveProvider } from '../../../helpers/activeContext';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import './android.scss';
import text from '../../../translations/translation.json';
import ServiceHead from '../../../components/Services/Head';
import ProjectsCompleted from '../../../components/Services/Shared/ProjectCompleted';
import Clover from '../../../components/Services/Shared/Clover/Clover';
import { remoteStorageUrl } from '../../../config';
import PinkishGradient from '../../../components/Services/Shared/PinkishGradient/PinkishGradient';
import Integrations from '../../../components/Services/Shared/ClientLoves';
import FullCycleDevelopment from '../../../components/Services/Shared/CheckTilesBox';
import CarryingServices from '../../../components/Services/CrossPlatform/CarryingServices';
import TechStack from '../../../components/Services/Shared/TechStack/TechStack';
import Domains from '../../../components/Home/Domains';
import OurScope from '../../../components/Services/Shared/Scope';
import Team from '../../../components/Services/Shared/Team';
import WorkProcess from '../../../components/Services/Shared/WorkProcess/WorkProcess';
import HomeForm from '../../../components/Shared/Form';
import FAQ from '../../../components/Home/FAQ';

const Android = ({ data }) => {
  const location = useLocation();
  return (
    <div className="android-service-page">
      <ActiveProvider value="android">
        <Helmet>
          <title>{text.metaTags.androidPage.title}</title>
          <meta
            name="description"
            content={text.metaTags.androidPage.description}
          />
          <link
            rel="canonical"
            href={`${process.env.REACT_APP_SITE_URL}${location.pathname}`}
          />
        </Helmet>
        <ServiceHead data={data.head} />
        <ProjectsCompleted
          data={data.projects_completed}
          cdnUrl={`${remoteStorageUrl}/resources/images/services_page/cross-platform/`}
        />
        <Clover
          title={data.clover.title}
          items={data.clover.items}
          cdnUrl={`${remoteStorageUrl}/resources/images/services_page/android/`}
          Icon={
            <img
              src={`${remoteStorageUrl}/resources/images/services_page/android/android.svg`}
              alt="clover"
            />
          }
        />
        <PinkishGradient
          title={data.performance.title}
          subtitle={data.performance.subtitle}
          items={data.performance.items}
          cdnUrl={`${remoteStorageUrl}/resources/images/services_page/cross-platform/`}
        />
        <Integrations
          title={data.integrations.title}
          items={data.integrations.items}
          cdnUrl={`${remoteStorageUrl}/resources/images/services_page/android/`}
        />
        <FullCycleDevelopment
          title={data.android_services.title}
          items={data.android_services.items}
          checkSrc={`${remoteStorageUrl}/resources/icons/checked_traced.svg`}
        />
        <CarryingServices
          title={data.services.title}
          items={data.services.items}
        />
        <TechStack
          title={data.tech_stack.title}
          images={data.tech_stack.images}
          cdnUrl={`${remoteStorageUrl}/resources/images/services_page/android/`}
        />
        <Domains text={data.domains} />
        <OurScope
          title={data.our_scope.title}
          items={data.our_scope.items}
          linkText={data.our_scope.link}
          cdnUrl={`${remoteStorageUrl}/resources/images/services_page/cross-platform`}
        />
        <Team
          title={data.team.title}
          items={data.team.items}
          cdnUrl={`${remoteStorageUrl}/resources/images/services_page/android/`}
          topGap={false}
        />
        <WorkProcess
          title={data.work_process.title}
          items={data.work_process.items}
          cdnUrl={`${remoteStorageUrl}/resources/images/services_page/cross-platform/`}
        />

        <HomeForm text={data.form} />

        <FAQ text={data.faq} />
      </ActiveProvider>
    </div>
  );
};
export default Android;
