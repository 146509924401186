import React from 'react';

import './textarea.scss';
import UploadClip from '../../../icons/uploadClip';
import DeleteIcon from '../../../icons/deleteIcon';
import Medium16px from '../../texts/16pxMedium';

const VALID_FILE_TYPES = [
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  'text/plain',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];

const TextAreaDropZone = (props) => {
  const { field, form, meta, placeholder } = props;
  return (
    <div className="textdropzone_container">
      <div
        className="textarea_box"
        style={{ border: meta.error ? '1px solid red' : '' }}
      >
        <textarea {...field} placeholder={placeholder} />
        <label className="upload_btn">
          <input
            name="files"
            type="file"
            multiple
            accept={VALID_FILE_TYPES.join(', ')}
            onChange={(event) => {
              const allowedFileSize = 5 * 1000000;
              const isValidFiles = Array.from(event.currentTarget.files).every(
                (file) =>
                  VALID_FILE_TYPES.includes(file.type) &&
                  file.size < allowedFileSize,
              );

              if (!isValidFiles) {
                let message =
                  'Please upload files with the following extensions: doc, docx, xls, xlsx, ppt, pptx, txt, pdf';

                if (
                  Array.from(event.currentTarget.files).some(
                    (file) => file.size > allowedFileSize,
                  )
                ) {
                  message = 'File size should be less than 5MB';
                }

                alert(message);
                return;
              }

              form.setFieldValue(
                'files',

                event.currentTarget.files,
              );
            }}
          />
          <UploadClip />
        </label>
      </div>

      {form.values.files.length > 0
        ? Array.from(form.values.files).map((file, index) => {
            return (
              <div className="file_box" key={index}>
                <div
                  className="delete_btn_box"
                  onClick={() => {
                    form.setFieldValue(
                      'files',
                      Array.from(form.values.files).filter(
                        (_, i) => i !== index,
                      ),
                    );
                  }}
                >
                  <DeleteIcon />
                </div>
                <Medium16px>{file.name}</Medium16px>
              </div>
            );
          })
        : null}
    </div>
  );
};

export default TextAreaDropZone;
