import React, { useLayoutEffect, useRef, useState } from 'react';
import './roadmap.scss';
import { remoteStorageUrl } from '../../../../../config';
import FadeUp from '../../../../../components/Animations/FadeUp';
import { useWindowDimensions } from '../../../../../helpers/resizeHandler';

const RoadmapItem = React.forwardRef(
  ({ item, imagesPath, className, distancesBetweenLogo, index }, ref) => {
    const gapBetweenLogo = distancesBetweenLogo && distancesBetweenLogo[index];
    return (
      <FadeUp className={className}>
        <div className={`${className}__image`} ref={ref}>
          <img
            src={`${remoteStorageUrl}${imagesPath}${item.img}`}
            alt={item.title}
          />
          {gapBetweenLogo && (
            <div>
              <div
                className={`${className}__image__connector-line`}
                style={{ height: `${gapBetweenLogo}px` }}
              ></div>
              <div
                className={`${className}__image__vector`}
                style={{ bottom: `${-gapBetweenLogo / 2}px` }}
              />
            </div>
          )}
        </div>
        <div className={`${className}__content`}>
          <div className={`${className}__content__title text-black-24px`}>
            {item.title}
          </div>
          <div
            className={`${className}__content__description text-medium-14px`}
          >
            {item.description}
          </div>
        </div>
      </FadeUp>
    );
  },
);
RoadmapItem.displayName = 'RoadmapItem';

const RoadmapWave = () => {
  return (
    <FadeUp className="roadmap__items__wave">
      <svg
        width="902"
        height="118"
        viewBox="0 0 902 118"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5 24.9998L103.8 67.813C157.314 92.9069 219.846 89.4835 270.301 58.6979L276.403 54.9743C331.327 21.4617 400.724 22.9456 454.165 58.7752V58.7752C507.949 94.8353 577.858 96.0878 632.9 61.9775L639.623 57.8109C689.856 26.681 752.301 22.6404 806.126 47.0372L896.5 88"
          stroke="#C9D1E3"
          strokeDasharray="6 6"
        />
        <circle cx="724" cy="30" r="15" fill="#ECF1FA" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M726.562 30L722 25.2651L723.219 24L729 30L723.219 36L722 34.7349L726.562 30Z"
          fill="#3095F2"
        />
        <circle cx="366" cy="30" r="15" fill="#ECF1FA" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M368.562 30L364 25.2651L365.219 24L371 30L365.219 36L364 34.7349L368.562 30Z"
          fill="#3095F2"
        />
        <circle cx="189" cy="84" r="15" fill="#ECF1FA" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M191.562 84L187 79.2651L188.219 78L194 84L188.219 90L187 88.7349L191.562 84Z"
          fill="#3095F2"
        />
        <circle cx="15" cy="27" r="15" fill="#ECF1FA" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.5621 27L13 22.2651L14.219 21L20 27L14.219 33L13 31.7349L17.5621 27Z"
          fill="#3095F2"
        />
        <circle cx="543" cy="84" r="15" fill="#ECF1FA" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M545.562 84L541 79.2651L542.219 78L548 84L542.219 90L541 88.7349L545.562 84Z"
          fill="#3095F2"
        />
        <circle cx="892" cy="85" r="10" fill="#ECF1FA" />
        <circle cx="892" cy="85" r="3" fill="#3095F2" />
      </svg>
    </FadeUp>
  );
};

const DesktopRoadmap = ({ items, imagesPath }) => {
  const headItems = items.slice(0, 3);
  const bottomItems = items.slice(3);

  return (
    <div className="roadmap__items">
      <div className="roadmap__items__head">
        {headItems.map((item, index) => (
          <RoadmapItem
            key={index}
            item={item}
            imagesPath={imagesPath}
            className="roadmap__items__head__item"
          />
        ))}
      </div>
      <RoadmapWave />
      <div className="roadmap__items__bottom">
        {bottomItems.map((item, index) => (
          <RoadmapItem
            key={index}
            item={item}
            imagesPath={imagesPath}
            className="roadmap__items__bottom__item"
          />
        ))}
      </div>
    </div>
  );
};

const MobileRoadmap = ({
  items,
  imagesPath,
  itemRefs,
  distancesBetweenLogo,
}) => {
  return (
    <div className="roadmap__items">
      <div className="roadmap__items__head">
        {items.map((item, index) => (
          <RoadmapItem
            key={index}
            item={item}
            index={index}
            imagesPath={imagesPath}
            className="roadmap__items__head__item"
            ref={(el) => (itemRefs.current[index] = el)}
            distancesBetweenLogo={distancesBetweenLogo}
          />
        ))}
      </div>
    </div>
  );
};

const Roadmap = ({ data, ...rest }) => {
  const { width } = useWindowDimensions();
  const itemRefs = useRef([]);
  const [distancesBetweenLogo, setDistancesBetweenLogo] = useState([]);

  useLayoutEffect(() => {
    if (itemRefs.current.length > 1) {
      const calculatedDistances = [];
      for (let i = 0; i < itemRefs.current.length - 1; i++) {
        if (itemRefs.current[i] && itemRefs.current[i + 1]) {
          const rect1 = itemRefs.current[i].getBoundingClientRect();
          const rect2 = itemRefs.current[i + 1].getBoundingClientRect();
          const dx = rect1.left - rect2.left;
          const dy = rect1.bottom - rect2.top;
          const distance = Math.sqrt(dx * dx + dy * dy);
          calculatedDistances.push(distance);
        }
      }
      setDistancesBetweenLogo(calculatedDistances);
    }
  }, [itemRefs, width]);

  return (
    <div className="roadmap" {...rest}>
      <h2
        className="text-heavy-32px roadmap__title"
        dangerouslySetInnerHTML={{ __html: data.title }}
      />
      {width > 1250 ? (
        <DesktopRoadmap items={data.items} imagesPath={data.imagesPath} />
      ) : (
        <MobileRoadmap
          items={data.items}
          imagesPath={data.imagesPath}
          itemRefs={itemRefs}
          distancesBetweenLogo={distancesBetweenLogo}
        />
      )}
    </div>
  );
};

export default Roadmap;
