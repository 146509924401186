import React from 'react';
import text from '../../translations/translation.json';
import './privacyPolicyMain.scss';
import ResponsiveBoxHead from '../../components/UI/ResponsiveBoxHead';
import NewMain from '../../components/NewMain/NewMain';
import ResponsiveBox from '../UI/ResponsiveBox';

export default () => (
  <div className="privacy-policy">
    <ResponsiveBoxHead style={{ paddingBottom: '2.5rem' }}>
      <div className="privacy-policy_header">
        <NewMain
          title={text.privacyPolicy.title}
          text={text.privacyPolicy.updated_at}
        />
      </div>
    </ResponsiveBoxHead>
    <ResponsiveBox topGap={false}>
      <div className="description">
        <p>
          This Privacy Policy outlines how we handle the gathering, utilization,
          and sharing of Your data while utilizing Our services. It also informs
          You about Your privacy entitlements and the legal safeguards in place.
          Our utilization of Your personal data is geared towards enhancing and
          delivering the service.
        </p>
        <p>
          When You engage with the Service, You indirectly consent to the data
          collection and its utilization as delineated in this Privacy Policy.
        </p>
        <p style={{ fontSize: '16px' }}>
          Our data collection and processing rules are designed to comply with
          GDPR rules and recommendations.
        </p>
        <h2>INTERPRETATION AND DEFINITIONS</h2>
        <h3>INTERPRETATION</h3>
        <p>
          The meanings assigned to words with capitalized initial letters are
          specified within certain contexts. These definitions remain
          consistent, regardless of whether these terms are used in singular or
          plural forms.
        </p>

        <h3>DEFINITIONS</h3>

        <p>For the purposes of this Privacy Policy:</p>
        <p>
          <b>Affiliate</b> means an entity that controls, is controlled by or is
          under common control with a party, where "control" means ownership of
          50% or more of the shares, equity interest or other securities
          entitled to vote for election of directors or other managing
          authority.
        </p>
        <p>
          <b>Company</b> (referred to as either "the Company", "We", "Us" or
          "Our" in this Agreement) refers to <b>Perpetio OÜ</b>, Kaupmehe tn
          7-120, Tallinn, Estonia, 10114.
        </p>
        <p>
          <b>Cookies</b> are small files that are placed on Your computer,
          mobile device or any other device by a Website, containing the details
          of Your browsing history on that Website among its many uses.
        </p>
        <p>
          <b>Country</b> refers to: Estonia.
        </p>
        <p>
          <b>Device</b> means any device that can access the Service such as a
          computer, a cellphone or a digital tablet.
        </p>
        <p>
          <b>Personal Data (Data)</b> is any information that relates to an
          identified or identifiable individual.
        </p>
        <p>
          <b>Service</b> - IOS development, Android development, Flutter
          development and other services related to the Website.
        </p>
        <p>
          <b>Service Provider</b> means any natural or legal person who
          processes the data on behalf of the Company. It refers to third- party
          companies or individuals employed by the Company to facilitate the
          Service, to provide the Service on behalf of the Company, to perform
          services related to the Service or to assist the Company in analyzing
          how the Service is used.
        </p>
        <p>
          <b>Usage Data</b> refers to data collected automatically, either
          generated by the use of the Service or from the Service infrastructure
          itself (for example, the duration of a page visit).
        </p>
        <p>
          <b>Website</b> refers to perpet.io, accessible from https://perpet.io/
        </p>
        <p>
          <b>You</b> - mean the individual accessing or using the Service, or
          the company, or other legal entity on behalf of which such individual
          is accessing or using the Service, as applicable.
        </p>
        <h2>COLLECTING AND USING YOUR PERSONAL DATA</h2>
        <h3>TYPES OF DATA COLLECTED</h3>
        <p>Here are the different types of information we gather or process:</p>
        <p>
          <b>Social data:</b> When You create an account, we collect details
          like Your email address, password, name, and email preferences. If You
          choose third-party services like Google or Apple for signing up, we
          obtain the information You authorize from them.
        </p>
        <p>
          <b>Log Data and Device Information:</b> This category covers Your
          internet protocol (IP) address, browser type and version, time zone
          settings and location, browser plug-in types and versions, as well as
          operating system and platform data.
        </p>
        <p>
          <b>Sales and Support Data</b>: This pertains to personal information
          You provide when interacting with Our customer support and sales
          teams.
        </p>
        <p>
          <b>User Content</b>: User content comprises all the text and documents
          You upload, input, or transmit while utilizing Our products.
        </p>
        <p>
          <b>Usage Data</b>: This includes information about how You use and
          engage with Our products. For example, it's used to generate weekly
          activity reports for You.
        </p>
        <p>
          <b>Third-Party Information</b>: This refers to data from Affiliates,
          subsidiaries, partners, and other sources related to Our business.
          This information, which might be at an aggregate level or more
          specific, could include details about organizations, industries,
          customer lists, Website visitors, marketing campaigns, and other
          aspects that contribute to improving the usefulness of Our
          information. For instance, it could involve correlating IP addresses
          with zip codes or countries or assessing the performance of online
          marketing or email campaigns.
        </p>
        <p>
          <b>Cookie Information and Identifiers</b>: We use Cookies and other
          identifiers to recognize your browser or Device, thereby enhancing,
          safeguarding, and refining Our products. Our Cookie Policy offers more
          details. It's important to note that Our products currently do not
          respond to Do Not Track requests.
        </p>
        <p>
          Your Personal Data will be stored in Our databases or in secured
          servers.
        </p>
        <h3>COLLECTION OF INFORMATION FROM CHILDREN</h3>
        <p>
          Company does not knowingly collect Personal Data from children under
          the age of 13. If We determine We have collected Personal Data from a
          child younger than 13 years of age, We will take reasonable measures
          to remove that information from Our systems.
        </p>
        <p>
          If You are under the age of 13, please do not submit any Personal Data
          through the Site and/or Products. We encourage parents and legal
          guardians to monitor their children’s Internet usage and to help
          enforce this Privacy Policy by instructing their children never to
          provide Personal Data through the Sites and/or Products without their
          permission.
        </p>
        <p>
          If Your jurisdiction requires a different age for parental consent for
          the provision of Personal Data, please contact Us.
        </p>
        <p>
          If we become aware that We have collected Data from children without
          verification of parental consent, we take steps to remove that
          information from Our servers.
        </p>
        <h2>USE OF YOUR PERSONAL DATA</h2>
        <p>The Company may use Personal Data for the following purposes:</p>
        <p>
          <b>To provide and maintain Our Service</b>, including to monitor the
          usage of Our Service.
        </p>
        <p>
          <b>For the performance of a contract</b>: the development, compliance
          and undertaking of the contract with Us through the Service.
        </p>
        <p>
          <b>To contact You</b>: To contact You by email, telephone calls,
          e-mail, or other equivalent forms of electronic communication, such as
          a mobile application's push notifications regarding updates or
          informative communications related to the functionalities, products or
          contracted Services, including the security updates, when necessary or
          reasonable for their implementation.
        </p>
        <p>
          Occasionally, We may wish to reach out to You with details regarding
          product launches, software enhancements, and exclusive promotions.
          Additionally, We might desire to apprise You of products and Services
          from Our business associates. Rest assured, We only dispatch marketing
          communications to You after obtaining Your explicit consent
          beforehand.
        </p>
        <p>
          <b>To manage Your requests</b>: To attend and manage Your requests to
          Us.
        </p>
        <p>
          <b>For business transfers</b>: We may use Your information to evaluate
          or conduct a merger, divestiture, restructuring, reorganization,
          dissolution, or other sale or transfer of some or all of Our assets,
          whether as a going concern or as part of a bankruptcy, liquidation, or
          similar proceeding, in which Personal Data held by Us about Our
          Service users is among the assets transferred.
        </p>
        <p>
          <b>Building and improving Our products.</b> We’re continually
          developing new methods to make Our products smarter, faster, more
          secure, and more helpful. We use data to help us do so. For example,
          We refer to Usage Data to help us decide what new features to build.
        </p>
        <p>
          <b>Ensuring Our and Our users’ safety and security.</b> We need
          Personal Data information to protect Company against fraud and abuse.
          For example, We may need to use Your account information to verify
          Your activity and protect against malicious, deceptive, or illegal
          activity.
        </p>
        <p>
          <b>Complying with legal obligations.</b> We use Personal Data where
          required by law or where We believe it is necessary to protect Our or
          others’ legal rights. For example, We may process account information
          to help You exercise Your data protection rights.
        </p>
        <p>
          <b>For other purposes:</b> We may use Your information for other
          purposes, such as data analysis, identifying usage trends and to
          evaluate and improve Our Service, products, Services, marketing.
        </p>
        <p>We may share Your Personal Data in the following situations:</p>
        <p>
          <b>With Service Providers:</b> We may share Your Personal Data with
          Service Providers to monitor and analyze the use of Our Service, to
          contact You.
        </p>
        <p>
          <b>For business transfers:</b> We may share or transfer Your Personal
          Data in connection with, or during negotiations of, any merger, sale
          of Company assets, financing, or acquisition of all or a portion of
          Our business to another company.
        </p>
        <p>
          <b>With Affiliates:</b> We may share Your information with Our
          Affiliates, in which case We will require those Affiliates to honor
          this Privacy Policy. Affiliates include Our parent company and any
          other subsidiaries, joint venture partners or other companies that We
          control or that are under common control with Us.
        </p>
        <p>
          <b>With business partners:</b> We may share Your information with Our
          business partners to offer You certain products or Services.
        </p>
        <p>
          <b>With other users:</b> when You share Personal Data or otherwise
          interact in the public areas with other users, such information may be
          viewed by all users and may be publicly distributed outside.
        </p>
        <p>
          <b>With Your consent:</b> We may disclose Your Personal Data for any
          other purpose with Your consent.
        </p>
        <h2>SECURE OF YOUR PERSONAL DATA</h2>
        <p>
          Your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside
          Your state, province, country or other governmental jurisdiction where
          the data protection laws may differ than those from Your jurisdiction.
        </p>
        <p>
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
        </p>
        <p>
          The Company will take all steps reasonably necessary to ensure that
          Your data is treated securely and in accordance with this Privacy
          Policy and no transfer of Your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your data and other Personal Data.
        </p>
        <p>
          <b>
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure.
          </b>{' '}
          While We strive to use commercially acceptable means to protect Your
          Personal Data, We cannot guarantee its absolute security.
        </p>
        <p>
          We use a combination of technical, physical, and logical safeguards to
          secure Your data. These safeguards include encryption, secure network
          configuration, data transfer restrictions, restricted employee access,
          and several other measures.
        </p>
        <p>
          All staff and officers who handle Relevant Data are aware of this
          Privacy Policy and have been given training in how to correctly
          collect, process, store and delete data.
        </p>
        <h2>DISCLOSURE OF YOUR PERSONAL DATA</h2>
        <p>
          We refrain from selling Your Personal Data. However, We do collaborate
          with specific third parties, as elaborated below, to facilitate,
          develop, safeguard, enhance, and market Our products, all in
          accordance with legal requirements as per the GDPR.
        </p>
        <p>
          <b>Reliable Service Providers.</b> We engage trusted Service Providers
          to deliver, safeguard, market, and enhance Our products. These
          reputable Third-Party entities are bound by agreements mandating
          adherence to Our data privacy and security stipulations. They are
          authorized to utilize Your data solely as directed by us.
        </p>
        <p>
          <b>
            Governmental entities, regulatory bodies, and duly authorized third
            parties.
          </b>
          We might divulge Your information to governmental bodies, regulators,
          and other third parties if We ascertain that such disclosure is
          reasonably essential to:
        </p>
        <p>
          (a) Adhere to relevant laws, regulations, legal proceedings, or
          appropriate governmental appeals;
        </p>
        <p>
          (b) Safeguard individuals from potential fatalities or severe physical
          harm;
        </p>
        <p>
          (c) Avert instances of fraud, damage, or misuse targeting Grammarly or
          Our user community;
        </p>
        <p>(d) Undertake an activity conducted in the public interest.</p>
        <p>
          <b>Change of legal form of the Company.</b> We may be required to take
          such actions in relation to a merger, acquisition, bankruptcy,
          reorganization, sale of assets or stock, public offering of
          securities, or steps pertaining to such undertakings (e.g., due
          diligence). Under such circumstances, a portion or all of Your
          Personal Data might be disclosed to or transferred to another entity,
          in accordance with the terms outlined in this Privacy Policy.
        </p>
        <p>
          <b>Third Parties’ Applications and Products.</b> Some Third-Party
          applications and Services that work with us may ask for permission to
          access Your information. Those applications will provide You with
          notice and request Your consent in order to obtain such access or
          information. Please consider Your selection of such applications and
          Services, and Your permissions, carefully.
        </p>
        <p>
          <b>Our Company does not</b> share Your Personal Data/personally
          identifiable information with third parties for the purpose of
          enabling them to deliver their advertisements to You.
        </p>
        <h2>RETENTION OF YOUR PERSONAL DATA</h2>
        <p>
          Company will retain your Personal data only for as long as is
          necessary for the purposes set out in this Policy.
        </p>
        <p>
          We will also retain usage data for internal analysis purposes. Usage
          data is generally retained for a shorter period of time, except when
          this data is used to strengthen the security or to improve the
          functionality of our Services, or we are legally obligated to retain
          this data for longer periods.
        </p>
        <p>
          In some cases, we choose to retain certain information (e.g., insights
          about Services use) in a depersonalized or aggregated form.
        </p>
        <p>
          You can make a specific request and be assured that your data is not
          being misused for anything other than the legitimate purpose for which
          it was originally provided.
        </p>
        <p>
          In the event of a security breach within Our systems, if feasible, We
          will strive to notify You and offer guidance on precautionary
          measures. Such notifications might be sent to the email address You
          provided us or via a notice posted on Our Website.
        </p>
        <h2>LEGAL GROUNDS FOR PROCESSING</h2>
        <p>
          The EU GDPR a legal foundation for Our utilization of Personal Data.
        </p>
        <p>Reasons for use:</p>
        <p>
          <b>Performance of Contract:</b> When We provide You with Our products
          or engage in communication regarding them, Our processing is founded
          on the execution of a contract.
        </p>
        <p>
          <b>Legitimate Interests:</b> We invoke Our legitimate business
          interests, as well as the interests of Our customers, when We identify
          and prevent fraud and misuse to safeguard the security of customers,
          ourselves, or others. This also applies to refining Our products and
          promoting Our paid offerings to You.
        </p>
        <p>
          <b>Your Consent:</b> When We seek Your consent to process Your
          Personal Data for a distinct purpose that We communicate to You, We
          establish processing on Your consent. Should You decide to revoke Your
          consent, You retain the option to withdraw it at any time, leading to
          the cessation of data processing for that particular purpose.
        </p>
        <p>
          <b>Compliance with Legal Obligations:</b> In cases where We use Your
          Personal Data to conform with legal requirements, Our processing is
          predicated on Our obligation to adhere to the law.
        </p>
        <p>
          Please note that the exercise of Your rights is subject to certain
          limitations under EU law and/or local law in EU member states, as set
          out in Section 23 of the GDPR. In addition, if We cannot identify You,
          We will not facilitate the enforcement and exercise of Your rights.
          Please note that if You exercise Your right to withdraw Your consent,
          such withdrawal will not affect the lawfulness of processing carried
          out before You exercise this right.
        </p>
        <h2>YOUR RIGHTS</h2>
        <p>
          If You are a resident of the European Economic Area (EEA), You have
          certain data protection rights. Company aims to take reasonable steps
          to allow You to correct, amend, delete or limit the use of Your
          Personal Data.
        </p>
        <p>
          If You wish to be informed about what Personal Data We hold about You
          and if You want it to be removed from our systems, please contact us.
        </p>
        <p>
          In certain circumstances, you have the following Data Protection
          rights:
        </p>
        <p>
          <b>Right to information and access.</b> You can ask us for information
          about what personal data is being processed and the rationale for such
          processing. We ensure Your right to get access to Your Personal Data
          that is being processed.
        </p>
        <p>
          <b>Right to rectification.</b> You can ask us for modifications to
          your Personal Data in case You believe that Your personal data is not
          up to date or accurate.
        </p>
        <p>
          <b>Right to withdraw consent.</b> You can withdraw a previously given
          consent for the processing of Your Personal Data for a purpose.
        </p>
        <p>
          <b>Right to be forgotten.</b> You can ask for the deletion of their
          data. This applies to situations where You would like to stop using
          Our Services.
        </p>
        <p>
          <b>Right for data portability.</b> You can ask to transfer of Your
          Personal Data. The Personal Data will be provided or transferred in a
          machine-readable electronic format.
        </p>
        <p>
          <b>Right in relation to automated decision-making and profiling.</b>
          You have the right to not be subject to entirely automatic profiling
          algorithms. If any such algorithms are used, You have the right to
          know details of their operation.
        </p>
        <p>
          All the requests regarding the data subject rights should be sent in a
          written form by the user or its legal representative by email at{' '}
          <a href="mailto:contact@perpet.io">contact@perpet.io</a>.
        </p>
        <p>
          Please note that we may ask You to verify Your identity before
          responding to such requests.
        </p>
        <p>
          You have the right to complain to a Data Protection Authority about
          Our collection and use of Your Personal Data. For more information,
          please contact Your local data protection authority in the European
          Economic Area (EEA).
        </p>
        <p>
          <b>California residents.</b> If you’re a California resident, You have
          certain privacy rights under California law, including the California
          Consumer Privacy Act of 2018 (“CCPA”).
        </p>
        <h2>CHANGES TO THIS PRIVACY POLICY</h2>
        <p>
          To ensure alignment with alterations in Our Sites, Products, Services,
          business operations, and applicable legal regulations, We may find it
          necessary to update this Privacy Policy. Nevertheless, Our steadfast
          commitment to upholding Your privacy remains unchanged. Any
          substantial modifications that have an impact on Your rights under
          this Privacy Policy will be showcased through revisions directly
          within this Privacy Policy. These changes, along with their effective
          date, will be prominently presented in an easily accessible area on
          the Sites.
        </p>
        <p>
          We recommend periodically revisiting this page to remain apprised of
          any adjustments. Please be aware that Your continued use of Perpetio
          OÜ’s Sites and/or Products following any modification signifies Your
          acceptance of, and consent to abide by, the revised Privacy Policy. If
          You disagree with any alterations to this Privacy Policy and wish to
          exclude Your information from it, the necessary action would be to
          discontinue using the Sites and/or Products.
        </p>
        <h2>CONTACT US</h2>
        <p>
          If You have any questions about this Privacy Policy, You can contact
          us:
        </p>
        <p>
          By email: <a href="mailto:contact@perpet.io">contact@perpet.io</a>.
        </p>
        <p>
          By visiting this page on Our Website:{' '}
          <a href="https://perpet.io/about">https://perpet.io/about</a>
        </p>
      </div>
    </ResponsiveBox>
  </div>
);
