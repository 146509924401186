import React from 'react';
import './businessSteps.scss';
import useABGetVariant from '../../../../../helpers/useABGetVariant';
import ResponsiveBox from '../../../../../components/UI/ResponsiveBox';
import FadeUp from '../../../../../components/Animations/FadeUp';
import BlockHeader from '../../../../../components/UI/texts/BlockHeader';

/**
 * Component for displaying business steps with titles, subtitles, buttons, and items.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.data - The data for the business steps.
 * @param {string} props.data.title - The title of the business steps.
 * @param {string} [props.data.subtitle] - The subtitle of the business steps.
 * @param {Object} props.data.btn1 - The first button data.
 * @param {string} props.data.btn1.title - The title of the first button.
 * @param {string} props.data.btn1.link - The link for the first button.
 * @param {Object} props.data.btn2 - The second button data.
 * @param {string} props.data.btn2.title - The title of the second button.
 * @param {string} props.data.btn2.link - The link for the second button.
 * @param {Array<Object>} props.data.items1 - The items for the first button.
 * @param {string} props.data.items1[].title - The title of the first item.
 * @param {string} props.data.items1[].subtitle - The subtitle of the first item.
 * @param {Array<Object>} props.data.items2 - The items for the second button.
 * @param {string} props.data.items2[].title - The title of the second item.
 * @param {string} props.data.items2[].subtitle - The subtitle of the second item.
 * @param {Object} rest - The rest of the props.
 * @returns {JSX.Element} The rendered component.
 */

const BusinessSteps = ({ data, ...rest }) => {
  const { title, subtitle, items1, items2, btn1, btn2 } = data;
  const abLabel = useABGetVariant('digitalization', 'business');

  return (
    <div className="business-wrapper" {...rest}>
      <ResponsiveBox>
        <FadeUp>
          <BlockHeader dangerouslySetInnerHTML={{ __html: title }} />
        </FadeUp>
        {subtitle && (
          <FadeUp className="business__subtitle">
            <p
              className="text-medium-24px"
              dangerouslySetInnerHTML={{ __html: subtitle }}
              style={{ textAlign: 'center', color: 'var(--p-text-color)' }}
            />
          </FadeUp>
        )}
        <div className="business__block-tiles">
          {[
            { btn: btn1, items: items1 },
            { btn: btn2, items: items2 },
          ].map(({ btn, items }, idx) => (
            <div key={idx} className="business__block-tiles__box">
              {btn.link ? (
                <FadeUp className="button-box">
                  <a href={btn.link}>
                    <div
                      className="text-heavy-16px"
                      style={{
                        color: 'white',
                        textAlign: 'center',
                        textTransform: 'uppercase',
                      }}
                      dangerouslySetInnerHTML={{ __html: abLabel || btn.title }}
                    />
                  </a>
                </FadeUp>
              ) : (
                <FadeUp className="button-box">
                  <div
                    className="text-heavy-16px"
                    style={{
                      color: 'white',
                      textAlign: 'center',
                      textTransform: 'uppercase',
                    }}
                    dangerouslySetInnerHTML={{ __html: abLabel || btn.title }}
                  />
                </FadeUp>
              )}
              {items.map((item, index) => (
                <FadeUp key={index} className="content-box">
                  <BlockHeader
                    dangerouslySetInnerHTML={{ __html: item.title }}
                    variant="h3"
                    className="text-heavy-22px"
                  />
                  <div
                    className="text-medium-16px content-box__subtitle"
                    dangerouslySetInnerHTML={{ __html: item.subtitle }}
                    style={{ color: 'var(--p-text-color)' }}
                  />
                </FadeUp>
              ))}
            </div>
          ))}
        </div>
      </ResponsiveBox>
    </div>
  );
};

export default BusinessSteps;
