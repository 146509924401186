import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { ActiveProvider } from '../../../helpers/activeContext';
import text from '../../../translations/translation.json';
import ProjectsCompleted from '../../../components/Services/Shared/ProjectCompleted';
import { remoteStorageUrl } from '../../../config';
import PinkishGradient from '../../../components/Services/Shared/PinkishGradient/PinkishGradient';
import { useWindowDimensions } from '../../../helpers/resizeHandler';
import ClientsLove from '../../../components/Services/Shared/ClientLoves';
import Domains from '../../../components/Home/Domains';
import CarryingServices from '../../../components/Services/CrossPlatform/CarryingServices';
import TechStack from '../../../components/Services/Shared/TechStack/TechStack';
import Support from '../../../components/Services/FullCycle/Support/Support';
import reviews from '../../../translations/reviews.json';
import Reviews from '../../../components/Services/FullCycle/Reviews/Reviews';
import Form from '../../../components/Shared/Form';
import FAQ from '../../../components/Home/FAQ';
import ServiceHeadDark from '../../../components/Services/HeadDark';
import HeadSlider from '../../../components/Services/HeadSlider';
import NumberedList from '../../../components/Services/NumberedList';
import RecentBlogPosts from '../../../components/Shared/RecentBlogPosts';
import './uiux.scss';
import Cases from '../../../components/Home/Cases';

const UI_UX = ({ data }) => {
  const location = useLocation();
  const { width } = useWindowDimensions();
  const cdnUrl = `${remoteStorageUrl}/resources/images/services_page/ui-ux/`;

  return (
    <div className="uiux-dark">
      <ActiveProvider value="ui-ux">
        <Helmet>
          <title>{text.metaTags.ui_uxPage.title}</title>
          <meta
            name="description"
            content={text.metaTags.ui_uxPage.description}
          />
          <link
            rel="canonical"
            href={`${process.env.REACT_APP_SITE_URL}${location.pathname}`}
          />
        </Helmet>

        <ServiceHeadDark data={data.head} />

        <HeadSlider
          data={data.slider}
          cdnUrl={`${remoteStorageUrl}/resources/images/services_page/ui-ux/slider`}
        />

        <ProjectsCompleted
          data={data.projects_completed}
          cdnUrl={`${remoteStorageUrl}/resources/images/services_page/cross-platform/`}
          twoColumnMobileLayout
        />

        <NumberedList
          title={data.numbered_list.title}
          subtitle={data.numbered_list.subtitle}
          items={data.numbered_list.items}
        />

        <PinkishGradient
          title={data.performance.title}
          subtitle={data.performance.subtitle}
          items={data.performance.items}
          cdnUrl={cdnUrl}
          mobile_image_near_title={width < 575 ? true : false}
          smallSubtitle
          direction="horizontal"
        />

        <Cases text={data.cases} rounded squareSize textCentered />

        <ClientsLove
          title={data.clients_love.title}
          items={data.clients_love.items}
          cdnUrl={`${cdnUrl}love/`}
        />

        <Domains text={data.domains} />

        <CarryingServices
          title={data.services.title}
          items={data.services.items}
          topGap={true}
          bottomGap={false}
          cdnUrl={`${cdnUrl}`}
        />

        <TechStack
          title={data.tech_stack.title}
          images={data.tech_stack.images}
          cdnUrl={`${remoteStorageUrl}/resources/images/tech_stack/`}
        />

        <Support data={data.support} cdnUrl={cdnUrl} darkTheme />

        <Reviews
          data={reviews.data}
          title={'Customers <span>about us</span>'}
          withBg
        />

        <RecentBlogPosts title={data.posts.title} />

        <Form text={data.form} darkTheme />

        <FAQ text={data.faq} darkTheme />
      </ActiveProvider>
    </div>
  );
};

export default UI_UX;
