import React, { useContext, useEffect } from "react";
import "./notFound.scss";
import text from "../../translations/translation.json";
import HttpContext from "../../../server/httpContext";
import withRouter from "../../utils/withRouter";

const NotFound = (props) => {
  let context = useContext(HttpContext);
  context.NotFound = true;

  useEffect(() => {
    props.handleNotFound(true);
  }, [props]);
  return (
    <div className="notFound_box">
      <div className="notFound_content">
        <div className="text404">
          4<span style={{ color: "black" }}>0</span>4
        </div>
        <span className="text">{text.notFound.text}</span>
        <a href={text.notFound.buttonLink}>{text.notFound.buttonText}</a>
      </div>
    </div>
  );
};

export default withRouter(NotFound);
