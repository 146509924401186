import React from "react";

import NewMain from "../../../components/NewMain/NewMain";
import text from "../../../translations/translation.json";
import LazyImage from "../../UI/LazyImage";

import "./header.scss";
import FadeUp from "../../Animations/FadeUp";

const Header = () => {
  return (
    <div className="home_head_container responsive-page-head-top_bottom-gap">
      <div className="max-width-container ">
        <FadeUp className="responsive-container">
          <NewMain title={text.main_new.title} text={text.main_new.text} />
          <div className="badges ">
            <div className="badges_group">
              {text.homePage_v2_0.badges.map((badge, index) => (
                <a
                  key={index}
                  href={badge.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={badge.className}
                >
                  <LazyImage
                    imageLink={badge.img}
                    path={badge.path}
                    alt={badge.alt}
                  />
                </a>
              ))}
            </div>
          </div>
        </FadeUp>
      </div>
    </div>
  );
};

export default Header;
