import React, { useState } from "react";
import Title from "../PerpetioTitle";
import text from "../../translations/translation.json";
import Slider from "react-slick";
import "./ourProjects.scss";
import withRouter from "../../utils/withRouter";
import WorkProduct from "../../containers/Works/WorkProduct/WorkProduct";
import Lightbox from "react-image-lightbox";
import { remoteStorageUrl } from "../../config";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ResponsiveBox from "../../components/UI/ResponsiveBox";

const OurProjects = (props) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const image = text.works.items.find((img) => img.id === photoIndex);
  if (image && image.link === "burn") {
    props.history.navigate(`/projects/${image.link}`);
  }
  const workItems = text.works.items.slice(0, 4);

  const settings = {
    infinite: true,
    // autoplay: true,
    // autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 3,
    swipeToSlide: true,
    touchThreshold: 50,
    buttons: false,
    dots: true,
    draggable: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 675,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          centerPadding: "0px",
          centerMode: true,
        },
      },
    ],
  };

  return (
    <div className="ourProjects">
      <ResponsiveBox topGap={props.topGap}>
        <Title
          title={text.about_us.sections.our_projects.title}
          subtitle={text.about_us.sections.our_projects.subtitle}
        ></Title>
        <div className="our_project-slider-container">
          <Slider {...settings}>
            {workItems.map((work, index) => {
              return (
                <div key={work} className="work-slide">
                  <WorkProduct
                    key={index}
                    work={work}
                    setIsOpen={setIsOpen}
                    setPhotoIndex={setPhotoIndex}
                    history={props.history}
                  />
                </div>
              );
            })}
          </Slider>
        </div>
        {image && isOpen && (
          <Lightbox
            enableZoom={true}
            mainSrc={`${remoteStorageUrl}/resources/images/portfolio/${image.details}`}
            onCloseRequest={() => setIsOpen(false)}
          />
        )}
      </ResponsiveBox>
    </div>
  );
};
export default withRouter(OurProjects);
