import React, { lazy, Suspense, useEffect, useState } from 'react';
import './keyStats.scss';
import FadeUp from '../../Animations/FadeUp';

let AnimatedNumbers = lazy(() => import('react-animated-numbers'));

/**
 * KeyStats component to display key statistics.
 *
 * @param {Object[]} data - Array of key statistics data.
 * @param {number} data[].value - The value of the statistic.
 * @param {string | undefined} [data[].firstUnit] - The unit before the value (optional).
 * @param {string} data[].lastUnit - The unit after the value.
 * @param {string} data[].description - The description of the statistic.
 *
 */
const KeyStats = ({ data, ...rest }) => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    let prevWidth = window.innerWidth;

    const updateKey = () => {
      if (window.innerWidth !== prevWidth) {
        prevWidth = window.innerWidth;
        setKey((prev) => prev + 1);
      }
    };

    window.addEventListener('resize', updateKey);
    return () => {
      window.removeEventListener('resize', updateKey);
    };
  }, []);
  return (
    <FadeUp threshold={0.35}>
      <div className="key-stats" {...rest}>
        {data.map((item, index) => (
          <div key={index} className="key-stats__item">
            <div className="key-stats__value">
              {item.firstUnit && (
                <div className="key-stats__unit text-medium-52px">
                  {item.firstUnit}
                </div>
              )}
              <div className="key-stats__number text-medium-52px">
                <Suspense>
                  <AnimatedNumbers
                    key={`type-${key}`}
                    locale="en-US"
                    includeComma
                    animateToNumber={item.value}
                    configs={[
                      { mass: 1, tension: 150, friction: 50, bounce: 1 },
                      { mass: 1, tension: 200, friction: 80, bounce: 1 },
                      { mass: 1, tension: 280, friction: 90, bounce: 1 },
                    ]}
                  />
                </Suspense>
              </div>
              <div className="key-stats__unit text-medium-52px">
                {item.lastUnit}
              </div>
            </div>
            <span className="visually-hidden">
              {item.firstUnit} {item.value} {item.lastUnit}
            </span>

            <p className={'text-medium-18px '}>{item.description}</p>
          </div>
        ))}
      </div>
    </FadeUp>
  );
};

export default KeyStats;
