import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import NewMain from '../../components/NewMain/NewMain';
import NewDevelopment from '../../components/NewDevelopment/NewDevelopment';
import text from '../../translations/translation.json';
import './AboutUs.scss';
import Multidisciplinarity from '../../components/About/Multidisciplinarity';
import OurValues from '../../components/About/OurValues';
import FactsAboutUs from '../../components/About/FactsAboutUs';
import Achievements from '../../components/About/Achievements';
import OurProjects from '../../components/OurProjects/OurProjects';
import withRouter from '../../utils/withRouter';
import AboutFAQ from '../../components/About/FAQ';
import ResponsiveBoxHead from '../../components/UI/ResponsiveBoxHead';

const AboutUs = () => {
  const location = useLocation();

  return (
    <div className="aboutUs">
      <Helmet>
        <title>About Us | Perpetio</title>
        <meta
          name="description"
          content="We are the reliable mobile app development company you were looking for! Boost your business with a slick mobile app from Perpetio."
        />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_SITE_URL}${location.pathname}`}
        />
      </Helmet>
      <ResponsiveBoxHead>
        <div className="portfolio_header">
          <NewMain title={text.about_us.title} text={text.about_us.text} />
        </div>
      </ResponsiveBoxHead>
      <NewDevelopment />
      <Multidisciplinarity />
      <OurValues />
      <FactsAboutUs />
      <Achievements />
      <AboutFAQ />
      <OurProjects />
    </div>
  );
};
export default withRouter(AboutUs);
