import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

import './form.scss';
import ResponsiveBox from '../../UI/ResponsiveBox';
import { crmData } from '../../../utils/crmData';
import CustomInput from '../../UI/formikForm/Input';
import PhoneInput from '../../UI/formikForm/PhoneInput';
import PhoneCodeInput from '../../UI/formikForm/PhoneCodeInput';
import SelectInput from '../../UI/formikForm/SelectInput';
import TextAreaDropZone from '../../UI/formikForm/TextAreaDropZone';
import CheckBoxInput from '../../UI/formikForm/CheckBoxInput';
import Mixed13px from '../../UI/texts/13pxMixed';
import FadeUp from '../../Animations/FadeUp';
import { getCaptchaKey, remoteStorageUrl } from '../../../config';
import translation from '../../../translations/translation2.json';
import BlockHeader from '../../UI/texts/BlockHeader';
const loadingIcon = `${remoteStorageUrl}/resources/icons/loading.svg`;
const successIcon = `${remoteStorageUrl}/resources/icons/success.svg`;
const dismissIcon = `${remoteStorageUrl}/resources/icons/bad.svg`;

const serviceOptions = [
  { label: 'UI/UX design', value: 'UI/UX design' },
  { label: 'iOS development', value: 'iOS development' },
  { label: 'Android development', value: 'Android development' },
  { label: 'Flutter development', value: 'Flutter development' },
  { label: 'Other', value: 'Other' },
];

const HomeForm = ({ text, darkTheme = false, forwardRef }) => {
  const [recaptchaToken, setRecaptchaToken] = useState('');

  const [modal, setModal] = useState(false);
  const handleModal = () => setModal(!modal);
  const [response, setResponse] = useState('');
  const [successResponse, setSuccessResponse] = useState(true);
  const handleRecaptchaChange = (value) => {
    setRecaptchaToken(value);
  };

  return (
    <div
      className={
        darkTheme ? 'form_container form_container-dark' : 'form_container'
      }
      ref={forwardRef}
    >
      <ResponsiveBox>
        <div className="form_content_box">
          <FadeUp>
            <BlockHeader
              style={{ textAlign: 'center' }}
              dangerouslySetInnerHTML={{
                __html: text.title,
              }}
            />
          </FadeUp>
          <div className="form_box">
            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                phoneCode: { code: 'US', dial_code: '+1' },
                phoneNumber: '',
                service: '',
                files: [],
                mailing: true,
                message: '',
              }}
              validationSchema={Yup.object({
                firstName: Yup.string().required('Please enter first name'),
                lastName: Yup.string().required('Please enter  last name'),
                service: Yup.string().required('Please choose a service'),
                email: Yup.string()
                  .email('Please enter a valid email address')
                  .required('Please enter a valid email address'),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                const data = new FormData();
                Object.values(values.files).forEach((file) => {
                  data.append('files', file);
                });
                data.append('email', values.email);
                data.append('firstName', values.firstName);
                data.append('lastName', values.lastName);
                data.append('message', values.message);
                data.append('formType', 'CLIENT_CONTACT');
                data.append('services', values.service);
                data.append('mailing', values.mailing);
                data.append(
                  'phoneNumber',
                  values.phoneCode.dial_code +
                    values.phoneNumber.replace(/[()-\s]/g, ''),
                );
                data.append(
                  'clientIdGA',
                  crmData().clientIdGA ? crmData().clientIdGA : 'none',
                );
                data.append(
                  'trafficType',
                  crmData().trafficType ? crmData().trafficType : 'none',
                );
                data.append(
                  'source',
                  crmData().source ? crmData().source : 'none',
                );
                data.append(
                  'firstTypeTraffic',
                  crmData().source ? crmData().firstTypeTraffic : 'none',
                );
                data.append(
                  'utm_medium',
                  crmData().utm_medium ? crmData().utm_medium : 'none',
                );
                data.append(
                  'utm_campaign',
                  crmData().utm_campaign ? crmData().utm_campaign : 'none',
                );
                data.append(
                  'utm_term',
                  crmData().utm_term ? crmData().utm_term : 'none',
                );
                data.append(
                  'entrance_point',
                  crmData().entrance_point ? crmData().entrance_point : 'none',
                );
                data.append(
                  'first_utm_source',
                  crmData().first_utm_source
                    ? crmData().first_utm_source
                    : 'none',
                );
                data.append(
                  'first_utm_medium',
                  crmData().first_utm_medium
                    ? crmData().first_utm_medium
                    : 'none',
                );
                data.append(
                  'first_utm_campaign',
                  crmData().first_utm_campaign
                    ? crmData().first_utm_campaign
                    : 'none',
                );
                data.append(
                  'first_utm_term',
                  crmData().first_utm_term ? crmData().first_utm_term : 'none',
                );
                data.append(
                  'first_visit_time',
                  crmData().first_visit_time
                    ? crmData().first_visit_time
                    : 'none',
                );
                data.append(
                  'first_entrance_point',
                  crmData().first_entrance_point
                    ? crmData().first_entrance_point
                    : 'none',
                );
                data.append('g-recaptcha-response', recaptchaToken);

                fetch(
                  `${window.location.protocol}//${window.location.host}/api/v1/send`,
                  {
                    method: 'POST',
                    body: data,
                  },
                )
                  .then((res) => res.json())
                  .then((result) => {
                    setSubmitting(false);
                    if (result.success) {
                      window &&
                        window.dataLayer &&
                        window.dataLayer.push({
                          event: 'Form_submission_lead',
                        });
                      setResponse(translation.formSuccessResponse);
                      handleModal && handleModal();
                      setSuccessResponse(true);
                    } else {
                      setResponse(translation.formBad_response);
                      handleModal && handleModal();
                      setSuccessResponse(false);
                    }
                  })
                  .catch((error) => {
                    setSubmitting(false);
                    setResponse(translation.formBad_response);
                    handleModal && handleModal();
                    setSuccessResponse(false);
                    console.error(error);
                  });
              }}
            >
              {({ isValid, dirty, isSubmitting }) => {
                return (
                  <Form id="contact-form">
                    <div className="form_inputs_box">
                      <div className="form_user_info">
                        <Field name="firstName">
                          {({ field, form, meta }) => (
                            <CustomInput
                              meta={meta}
                              field={field}
                              form={form}
                              placeholder={'First name'}
                              required={true}
                              type="text"
                            />
                          )}
                        </Field>
                        <Field name="lastName">
                          {({ field, form, meta }) => (
                            <CustomInput
                              meta={meta}
                              field={field}
                              form={form}
                              placeholder={'Last name'}
                              required={true}
                              type="text"
                            />
                          )}
                        </Field>
                        <Field name="email">
                          {({ field, form, meta }) => (
                            <CustomInput
                              meta={meta}
                              field={field}
                              form={form}
                              placeholder={'Your email'}
                              required={true}
                              type="email"
                            />
                          )}
                        </Field>
                        <div className="phone_box">
                          <Field name="phoneCode">
                            {({ field, form }) => (
                              <PhoneCodeInput field={field} form={form} />
                            )}
                          </Field>
                          <Field name="phoneNumber">
                            {({ field, form, meta }) => (
                              <PhoneInput
                                meta={meta}
                                field={field}
                                form={form}
                                placeholder={'Phone number'}
                                required={false}
                                type="tel"
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                      <Field name="service">
                        {({ field, form, meta }) => (
                          <SelectInput
                            field={field}
                            form={form}
                            meta={meta}
                            options={serviceOptions}
                            placeholder={'Select a service'}
                          />
                        )}
                      </Field>
                      <Field name="message">
                        {({ field, form, meta }) => (
                          <TextAreaDropZone
                            field={field}
                            form={form}
                            meta={meta}
                            placeholder={
                              text?.placeholders?.text ||
                              'Describe your project'
                            }
                          />
                        )}
                      </Field>
                      <Field name="mailing">
                        {({ field, form }) => (
                          <CheckBoxInput
                            field={field}
                            form={form}
                            text={
                              'I would like to receive industry best practices, company \
                              updates, new blog posts and use cases from Perpetio by email. \
                              I can opt-out freely and at any time.'
                            }
                          />
                        )}
                      </Field>
                      <Mixed13px className="submitting_text">
                        By submitting this form I consent to the processing of
                        the personal data that I have provided and declare my
                        agreement with the data protection regulations in the
                        data privacy statement{' '}
                        <Link to={'/privacy-policy'}>here</Link>.
                      </Mixed13px>
                    </div>
                    <div className="mb-2 mt-3 recaptcha-box">
                      <ReCAPTCHA
                        sitekey={getCaptchaKey()}
                        size="normal"
                        onChange={handleRecaptchaChange}
                      />
                    </div>
                    <div className="submitBtn_box">
                      <Button
                        type="submit"
                        disabled={!(isValid && dirty && recaptchaToken)}
                      >
                        {isSubmitting ? (
                          <img
                            className="loading-icon"
                            src={loadingIcon}
                            alt="loading-icon"
                          />
                        ) : (
                          'Send'
                        )}
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </ResponsiveBox>
      <Modal
        isOpen={modal}
        toggle={handleModal}
        centered
        size="md"
        className="modal-wrapper"
      >
        <ModalHeader toggle={handleModal}></ModalHeader>
        <ModalBody className="response row justify-content-center">
          <div>
            <img
              className="mb-4"
              src={successResponse ? successIcon : dismissIcon}
              alt="success-icon"
            />
          </div>
          <div>
            <p
              className="center"
              dangerouslySetInnerHTML={{ __html: response }}
            />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default HomeForm;
