import React from 'react';
import LazyLoad from 'react-lazyload';

import './solutions.scss';
import ResponsiveBox from '../../../../../components/UI/ResponsiveBox';
import FadeUp from '../../../../../components/Animations/FadeUp';
import BlockHeader from '../../../../../components/UI/texts/BlockHeader';

/**
 * Solutions component renders a list of solution items with lazy-loaded images and animated headers.
 *
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the solutions section, rendered as HTML.
 * @param {Array} props.items - An array of solution items.
 * @param {string} props.items[].img - The image URL for the solution item.
 * @param {string} props.items[].title - The title of the solution item, rendered as HTML.
 * @param {string} [props.items[].text] - The optional text description of the solution item, rendered as HTML.
 * @param {string} props.cdnUrl - The base URL for the CDN where images are hosted.
 *
 * @returns {JSX.Element} The rendered Solutions component.
 */

const Solutions = ({ title, items, cdnUrl }) => {
  return (
    <ResponsiveBox>
      <div className="solutions-container">
        <FadeUp>
          <BlockHeader dangerouslySetInnerHTML={{ __html: title }} />
        </FadeUp>

        <div className="solutions-wrapper">
          {items.map((item, index) => (
            <FadeUp key={index} className="solutions-item">
              <div className="solutions-image">
                <LazyLoad once offset={100}>
                  <img src={`${cdnUrl}${item.img}`} alt={item.alt} />
                </LazyLoad>
              </div>
              <div className="solutions-content">
                <BlockHeader
                  dangerouslySetInnerHTML={{ __html: item.title }}
                  variant="h3"
                />
                {item.description && (
                  <div
                    className="text-medium-16px solutions-text"
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                )}
              </div>
            </FadeUp>
          ))}
        </div>
      </div>
    </ResponsiveBox>
  );
};

export default Solutions;
