import React, { useState } from 'react';
import text from '../../../translations/translation.json';
import { useLocation } from 'react-router-dom';

import ServiceHead from '../../../components/Services/Head';
import { Helmet } from 'react-helmet-async';
import ProjectsCompleted from '../../../components/Services/Shared/ProjectCompleted';
import { remoteStorageUrl } from '../../../config';
import CheckTilesBox from '../../../components/Services/Shared/CheckTilesBox';
import HighPerformance from '../../../components/Services/Fitness_Wellnes/highPerformance';
import ClientsLove from '../../../components/Services/Shared/ClientLoves';
import TypesOfFitnessApp from '../../../components/Services/Shared/ImageSlider';
import './fitness&wellness';
import Cases from '../../../components/Services/Shared/Cases';
import CarryingServices from '../../../components/Services/CrossPlatform/CarryingServices';
import TechStack from '../../../components/Services/Shared/TechStack/TechStack';
import PinkishGradient from '../../../components/Services/Shared/PinkishGradient/PinkishGradient';
import CompanyServices from '../../../components/Shared/CompanyServices';
import Domains from '../../../components/Home/Domains';
import WorkProcess from '../../../components/Services/Shared/WorkProcess/WorkProcess';
import Form from '../../../components/Shared/Form';
import FAQ from '../../../components/Home/FAQ';

const FitnessWellness = ({ data }) => {
  const location = useLocation();
  const [
    mobileNextSliderFirstImageActive,
    setMobileNextSliderFirstImageActive,
  ] = useState(false);

  return (
    <>
      <Helmet>
        <title>{text.metaTags.fitness_wellness.title}</title>
        <meta
          name="description"
          content={text.metaTags.fitness_wellness.description}
        />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_SITE_URL}${location.pathname}`}
        />
      </Helmet>
      <ServiceHead data={data.head} />
      <ProjectsCompleted
        data={data.projects_completed}
        cdnUrl={`${remoteStorageUrl}/resources/images/services_page/fitness&wellness/`}
      />
      <CheckTilesBox
        title={data.next_gen_fitness_app.title}
        items={data.next_gen_fitness_app.items}
        subtitle={data.next_gen_fitness_app.subtitle}
        checkSrc={`${remoteStorageUrl}/resources/icons/checked_traced.svg`}
      />

      <div className="sliders-wrapper">
        <TypesOfFitnessApp
          data={data.types_of_fitness_app1}
          topGap={false}
          cdnUrl={`${remoteStorageUrl}/resources/images/services_page/fitness&wellness/imageSlider/`}
          path={'resources/images/services_page/fitness&wellness/imageSlider'}
          style={{
            transform: 'rotate(180deg)',
          }}
          mobileNextSliderFirstImageActive={mobileNextSliderFirstImageActive}
        />
        <TypesOfFitnessApp
          bottomGap={false}
          openFirstMobileBlock={false}
          data={data.types_of_fitness_app2}
          cdnUrl={`${remoteStorageUrl}/resources/images/services_page/fitness&wellness/imageSlider/`}
          path={'resources/images/services_page/fitness&wellness/imageSlider'}
          style={{
            transform: 'rotate(180deg)',
          }}
          setMobileNextSliderFirstImageActive={
            setMobileNextSliderFirstImageActive
          }
        />
      </div>
      <ClientsLove
        title={data.clients_love.title}
        items={data.clients_love.items}
        cdnUrl={`${remoteStorageUrl}/resources/images/services_page/fitness&wellness/`}
      />
      <HighPerformance
        data={data.high_performance}
        style={{
          background:
            'linear-gradient(145deg, #F3F2FF 0%, #E8FFFE 55.21%, #F5F6FF 100%)',
          transform: 'rotate(180deg)',
        }}
      />
      <Cases text={data.cases} threeBlockLayout={true} />
      <CarryingServices
        title={data.services.title}
        items={data.services.items}
        bottomGap={false}
      />
      <div className="tech-stack-wrapper">
        <TechStack
          title={data.tech_stack.title}
          images={data.tech_stack.images}
          cdnUrl={`${remoteStorageUrl}/resources/images/services_page/fitness&wellness/techStack/`}
        />
      </div>
      <PinkishGradient
        title={data.performance.title}
        subtitle={data.performance.subtitle}
        items={data.performance.items}
        cdnUrl={`${remoteStorageUrl}/resources/images/services_page/cross-platform/`}
      />
      <CompanyServices
        text={data.company_services}
        style={{ background: '#3095F2' }}
        textColor={'white'}
      />
      <Domains
        text={data.domains}
        bgColor={'#F1F7FC'}
        style={{ background: 'transparent' }}
      />
      <WorkProcess
        title={data.work_process.title}
        items={data.work_process.items}
        cdnUrl={`${remoteStorageUrl}/resources/images/services_page/cross-platform/`}
      />
      <Form text={data.form} />

      <FAQ text={data.faq} />
    </>
  );
};

export default FitnessWellness;
