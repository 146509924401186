import React from 'react';

import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import './link.scss';

const LinkButton = ({ children, ...props }) => {
  return (
    <Link className="link_button" {...props}>
      <Button color="primary">{children}</Button>
    </Link>
  );
};

export default LinkButton;
