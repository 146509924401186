import React, { useState } from 'react';
import FadeUp from '../Animations/FadeUp';
import './ourValues.scss';
import Title from '../../components/PerpetioTitle';
import text from '../../translations/translation.json';
import ResponsiveBox from '../../components/UI/ResponsiveBox';
import BlockHeader from '../UI/texts/BlockHeader';

const OurValues = () => {
  const [activeTab, setActiveTab] = useState(0);
  const handleActiveTab = (id) => {
    setActiveTab(id);
  };

  return (
    <div className="our-values-container">
      <ResponsiveBox>
        <div className="our-values">
          <div className="title-box">
            <Title
              title={text.about_us.sections.our_values.title}
              subtitle={text.about_us.sections.our_values.subtitle}
            />
          </div>
          <FadeUp className="list-box">
            {text.about_us.sections.our_values.list.map((item) => {
              return (
                <div key={item.id} className="list-item-box">
                  <div
                    key={item.id}
                    className="item-head"
                    onClick={() => handleActiveTab(item.id)}
                  >
                    <span className="list-item-number">{`0${
                      item.id + 1
                    }`}</span>
                    <div className="item-text-box">
                      <BlockHeader
                        variant="h3"
                        className={
                          activeTab === item.id
                            ? 'list-item-title active'
                            : 'list-item-title'
                        }
                        dangerouslySetInnerHTML={{ __html: item.title }}
                      />
                      <p
                        className={activeTab === item.id ? 'slide' : 'hidden'}
                        dangerouslySetInnerHTML={{ __html: item.text }}
                      />
                    </div>
                    <span className="list-item-mark">
                      {activeTab === item.id ? '-' : '+'}
                    </span>
                  </div>
                </div>
              );
            })}
          </FadeUp>{' '}
        </div>
      </ResponsiveBox>
      <div className="max-width-container">
        <div className="margin-right-left our-values"></div>
      </div>
    </div>
  );
};
export default OurValues;
