import React, { useState } from 'react';
import ImageSlider from '../../../../../components/Services/Shared/ImageSlider';
import './slider.scss';

const Slider = ({ data, cdnUrl, path }) => {
  const [
    mobileNextSliderFirstImageActive,
    setMobileNextSliderFirstImageActive,
  ] = useState(false);

  return (
    <div className="sliders-wrapper">
      <ImageSlider
        data={data.types[0]}
        topGap={false}
        cdnUrl={cdnUrl}
        path={path}
        style={{
          transform: 'rotate(180deg)',
        }}
        mobileNextSliderFirstImageActive={mobileNextSliderFirstImageActive}
      />
      <ImageSlider
        bottomGap={false}
        openFirstMobileBlock={false}
        data={data.types[1]}
        cdnUrl={cdnUrl}
        path={path}
        style={{
          transform: 'rotate(180deg)',
        }}
        setMobileNextSliderFirstImageActive={
          setMobileNextSliderFirstImageActive
        }
      />
    </div>
  );
};

export default Slider;
