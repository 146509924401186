import React from 'react';
import './platforms.scss';
import ResponsiveBox from '../../../../../components/UI/ResponsiveBox';
import { remoteStorageUrl } from '../../../../../config';
import FadeUp from '../../../../../components/Animations/FadeUp';
import { Link } from 'react-router-dom';

const Platforms = ({ data, ...rest }) => {
  return (
    <ResponsiveBox {...rest}>
      <div className="platforms">
        <FadeUp>
          <h2 className="platforms__title text-black-32px">
            {data.services.title}
          </h2>
        </FadeUp>

        <div className="platforms__services_items">
          {data.services.items.map((item, index) => (
            <Link key={index} to={item.href}>
              <FadeUp className="platforms__services_item">
                <img
                  src={`${remoteStorageUrl}${data.services.imagesPath}${item.logo}`}
                  alt={item.alt}
                  className="platforms__services_item_image"
                />
                <div className="platforms__services_item__text_wrapper">
                  <h2
                    className="platforms__services_item__text_wrapper__title text-black-24px"
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  />
                  <div className="platforms__services_item__text_wrapper__text text-medium-14px">
                    {item.text}
                  </div>
                </div>
              </FadeUp>
            </Link>
          ))}
        </div>
        {/* <FadeUp className="platforms__tech_stack">
          {data.tech_stack.images.map((item) => (
            <a href={item.href} key={item.src} target="_blank" rel="noreferrer">
              <img
                src={`${remoteStorageUrl}${data.tech_stack.imagesPath}${item.src}`}
                alt={item.alt}
                className="platforms__tech_stack_image"
              />
            </a>
          ))}
        </FadeUp> */}
      </div>
    </ResponsiveBox>
  );
};

export default Platforms;
