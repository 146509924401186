import React, { useState } from 'react';
import ResponsiveBox from '../../../UI/ResponsiveBox';
import FadeUp from '../../../Animations/FadeUp';
import LazyLoad from 'react-lazyload';
import Medium16px from '../../../UI/texts/16pxMedium';
import './imageSlider.scss';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import LazyImage from '../../../UI/LazyImage';
import SvgArrowUp from '../../../icons/arrow_up';
import BlockHeader from '../../../UI/texts/BlockHeader';

const ImageSlider = ({
  data,
  cdnUrl,
  path,
  topGap = true,
  bottomGap = true,
  openFirstMobileBlock = true,
  mobileNextSliderFirstImageActive = false,
  setMobileNextSliderFirstImageActive,
  activeItemBgColor = 'white',
  activeItemIconColor = 'linear-gradient(145deg, #F3F2FF 0%, #E8FFFE 55.21%, #F5F6FF 100%)',
  ...rest
}) => {
  const { title, items } = data;

  const [currentImgIndex, setCurrentImgIndex] = useState(0);
  const [prevImgIndex, setPrevImgIndex] = useState(0);
  const [openedMobileImage, setOpenedMobileImage] = useState(
    openFirstMobileBlock ? [1] : [],
  );

  const handleImageState = (index) => {
    const found = openedMobileImage.find((element) => element === index);
    if (!found) {
      setOpenedMobileImage([...openedMobileImage, index]);
      if (index === 1 && setMobileNextSliderFirstImageActive) {
        setMobileNextSliderFirstImageActive(true);
      }
      return;
    }
    if (index === 1 && setMobileNextSliderFirstImageActive) {
      setMobileNextSliderFirstImageActive(false);
    }
    setOpenedMobileImage(
      openedMobileImage.filter((element) => element !== index),
    );
  };

  return (
    <div {...rest}>
      <ResponsiveBox topGap={topGap} bottomGap={bottomGap}>
        <div className="image-slider-wrapper">
          {title && (
            <FadeUp className="image-slider-titleBox">
              <BlockHeader
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              />
            </FadeUp>
          )}
          <FadeUp className="image-slider-content-desktop">
            <div
              className={`image-slider-content-image-${
                currentImgIndex < prevImgIndex ? 'up' : 'down'
              }`}
            >
              <TransitionGroup>
                <CSSTransition
                  classNames="slide"
                  timeout={{ enter: 300, exit: 300 }}
                  key={currentImgIndex}
                >
                  <LazyImage
                    imageLink={items[currentImgIndex].image}
                    path={path}
                    alt={items[currentImgIndex].alt}
                  />
                </CSSTransition>
              </TransitionGroup>
            </div>
            <div className="image-slider-content-items">
              {items.map((item, index) => {
                return (
                  <div
                    className="image-slider-content-item"
                    key={index}
                    onMouseEnter={() => {
                      setPrevImgIndex(currentImgIndex);
                      setCurrentImgIndex(index);
                    }}
                    onClick={() => {
                      setPrevImgIndex(currentImgIndex);
                      setCurrentImgIndex(index);
                    }}
                    style={{
                      background:
                        currentImgIndex === index
                          ? activeItemBgColor
                          : 'transparent',
                      borderColor: [index, index + 1, index - 1].includes(
                        currentImgIndex,
                      )
                        ? 'transparent'
                        : undefined,
                      borderRadius: currentImgIndex === index && '6px',
                    }}
                  >
                    <div
                      className="lazyload-wrapper"
                      style={{
                        background:
                          currentImgIndex === index
                            ? activeItemIconColor
                            : 'transparent',
                      }}
                    >
                      <img alt={item.alt} src={`${cdnUrl}${item.logo}`} />
                    </div>
                    <div className="image-slider-content-item__text">
                      <BlockHeader
                        variant="h3"
                        style={{ textAlign: 'start' }}
                        dangerouslySetInnerHTML={{ __html: item.title }}
                      />
                      <Medium16px
                        style={{
                          textAlign: 'start',
                          color: 'var(--p-text-color)',
                        }}
                        dangerouslySetInnerHTML={{ __html: item.subtitle }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </FadeUp>
          <div className="image-slider-content-mobile">
            <div className="image-slider-content-items">
              {items.map((item, index) => {
                const active = openedMobileImage.find(
                  (element) => element === item.id,
                );
                const prevInactive = openedMobileImage.find(
                  (element) => element - 1 === item.id,
                );
                const hideLastBoxLine =
                  mobileNextSliderFirstImageActive &&
                  index === items.length - 1;
                return (
                  <div
                    className="image-slider-content-item"
                    key={`${index}+mobile`}
                    onClick={() => {
                      handleImageState(item.id);
                    }}
                    style={{
                      background: active ? activeItemBgColor : 'transparent',
                      borderColor:
                        (active || prevInactive || hideLastBoxLine) &&
                        'transparent',
                      borderRadius: active && '6px 6px 0px 0px',
                    }}
                  >
                    <FadeUp threshold={0.3}>
                      <div className="item-logo-box">
                        <LazyLoad
                          once
                          offset={100}
                          style={{
                            background: active
                              ? activeItemIconColor
                              : 'transparent',
                          }}
                        >
                          <img alt={item.alt} src={`${cdnUrl}${item.logo}`} />
                        </LazyLoad>
                        <div
                          className={`item-logo-box__arrow-${
                            active ? 'inactive' : 'active'
                          }`}
                        >
                          <SvgArrowUp />
                        </div>
                      </div>
                      <div className="image-slider-content-item__text">
                        <BlockHeader
                          variant="h3"
                          style={{ textAlign: 'start' }}
                          dangerouslySetInnerHTML={{ __html: item.title }}
                        />
                        <Medium16px
                          style={{
                            textAlign: 'start',
                            color: 'var(--p-text-color)',
                          }}
                          dangerouslySetInnerHTML={{ __html: item.subtitle }}
                        />
                      </div>
                      <div className="image-slider-content-mobile__hide-image-box">
                        <div
                          className={`${active ? 'showImage' : 'hideImage'}`}
                        >
                          <LazyImage
                            imageLink={items[index].image}
                            path={path}
                            alt={items[index].alt}
                          />
                        </div>
                      </div>
                    </FadeUp>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </ResponsiveBox>
    </div>
  );
};
export default ImageSlider;
