import React from 'react';
import FadeUp from '../../../../../components/Animations/FadeUp';
import ResponsiveBox from '../../../../../components/UI/ResponsiveBox';
import './multiplatformSolutions.scss';
import { remoteStorageUrl } from '../../../../../config';

const MultiplatformSolutions = ({ data, ...rest }) => {
  return (
    <ResponsiveBox>
      <div className="multiplatform" {...rest}>
        {data.items.map((item, index) => {
          return (
            <FadeUp key={index} className="multiplatform__item">
              <div className="multiplatform__item__image">
                <img
                  src={`${remoteStorageUrl}${data.imagesPath}${item.img}`}
                  alt={item.title}
                />
              </div>
              <div className="multiplatform__item__content">
                <div className="multiplatform__item__content__title">
                  <h3 className="text-heavy-24px">{item.title}</h3>
                </div>
                <div className="multiplatform__item__content__description text-medium-16px">
                  {item.text}
                </div>
              </div>
            </FadeUp>
          );
        })}
      </div>
    </ResponsiveBox>
  );
};

export default MultiplatformSolutions;
