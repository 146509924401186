import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { ActiveProvider } from '../../../helpers/activeContext';
import Header from './components/Header/Header';
import { remoteStorageUrl } from '../../../config';
import HeadSlider from '../../../components/Services/HeadSlider';
import Form from '../../../components/Shared/Form/v2/Form';
import FAQ from '../../../components/Home/FAQ';
import './aiBased.scss';
import KeyStats from '../../../components/common/keyStats/KeyStats';
import AiAdvantages from './components/AiAdvantages/AiAdvantages';
import BusinessSteps from './components/BusinessSteps/BusinessSteps';
import Solutions from './components/Solutions/Solutions';
import Slider from './components/Slider/Slider';
import Cases from './components/Cases/Cases';
import Platforms from './components/Platforms/Platforms';
import MultiplatformSolutions from './components/MultiplatformSolutions/MultiplatformSolutions';
import CompanyServices from '../../../components/Shared/CompanyServices';
import Domains from '../../../components/Home/Domains';
import Roadmap from './components/Roadmap/Roadmap';

const AIBasedApp = ({ data }) => {
  const location = useLocation();
  const cdnUrl = `${remoteStorageUrl}/resources/images/solutions_pages/ai-based`;
  return (
    <div className="ai-based">
      <ActiveProvider value="aiBased">
        <Helmet>
          <title>{data.metaTags.title}</title>
          <meta name="description" content={data.metaTags.description} />
          <link
            rel="canonical"
            href={`${process.env.REACT_APP_SITE_URL}${location.pathname}`}
          />
        </Helmet>
        <div className="dark-wrapper">
          <Header data={data.header} />
          <HeadSlider
            data={data.slider}
            cdnUrl={`${remoteStorageUrl}/resources/images/services_page/ui-ux/slider`}
          />
          <KeyStats data={data.aiStats} />
          <AiAdvantages data={data.aiAdvantages} />
        </div>
        <BusinessSteps
          data={data.aiBusinessSteps}
          style={{
            background:
              'linear-gradient(145deg, #F3F2FF 0%, #E8FFFE 55.21%, #F5F6FF 100%)',
            transform: 'rotate(180deg)',
          }}
        />
        <div style={{ background: '#020220' }}>
          <Solutions
            title={data.aiFeatures.title}
            items={data.aiFeatures.items}
            cdnUrl={`${cdnUrl}/solutions/`}
          />
        </div>
        <Slider
          data={data.typesOfApps}
          cdnUrl={`${cdnUrl}/Slider/`}
          path={'resources/images/solutions_pages/ai-based/Slider'}
        />
        <div className="cases-wrapper">
          <Cases text={data.cases} rounded squareSize textCentered />
        </div>
        <Platforms data={data.platforms} />
        <div className="multiplatform-wrapper">
          <MultiplatformSolutions
            data={data.multiPlatformAISolutions}
            style={{ transform: 'rotate(180deg)' }}
          />
        </div>
        <CompanyServices
          text={data.aiTeamProjectSolutions}
          style={{ background: '#3095F2' }}
          textColor={'white'}
        />
        <Domains
          text={data.domains}
          bgColor={'#F1F7FC'}
          style={{ background: 'transparent' }}
        />
        <Roadmap data={data.roadmap} />
        <div className="black-wrapper">
          <Form text={data.form} />
        </div>

        <div
          style={{
            background:
              'linear-gradient(145deg, #F3F2FF 0%, #E8FFFE 55.21%, #F5F6FF 100%)',
            transform: 'rotate(180deg)',
          }}
        >
          <FAQ text={data.faq} style={{ transform: 'rotate(180deg)' }} />
        </div>
      </ActiveProvider>
    </div>
  );
};

export default AIBasedApp;
