import React, { useState, useEffect } from 'react';
import { Col, Row, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { remoteStorageUrl } from '../../../../config';
import Form from '../../Form/Form';
import data from '../../../../translations/translation.json';
import './contact.scss';
import FadeUp from '../../../Animations/FadeUp';

const recruiterPhoto = `${remoteStorageUrl}/resources/images/careers/recruiter.png`;
const successIcon = `${remoteStorageUrl}/resources/icons/success.svg`;
const dismissIcon = `${remoteStorageUrl}/resources/icons/bad.svg`;

const Contact = ({ text, pos }) => {
  const [position, setPosition] = useState('');
  const [response, setResponse] = useState('');
  const [successResponse, setSuccessResponse] = useState(true);
  const [modal, setModal] = useState(false);

  const handleModal = () => setModal(!modal);

  useEffect(() => {
    modal
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');
  }, [modal]);

  const formData = data.careers.form;

  useEffect(() => setPosition(pos), [pos]);

  return (
    <Col className="career-contact-wrapper" lg={5}>
      <FadeUp>
        <h4 className="title mb-3 mb-sm-4">{text.title}</h4>
        <Row className="recruiter">
          <img
            className="recruiter-photo"
            src={recruiterPhoto}
            alt="recruiter"
          />
          <Col className="recruiter-col">
            <h5 className="recruiter-name">{text.recruiter.name}</h5>
            <p className="mb-3" style={{ color: '#7C7C87' }}>
              {text.recruiter.position}
            </p>
            <a href={`mailto:${text.recruiter.email}`}>
              {text.recruiter.email}
            </a>
          </Col>
        </Row>
      </FadeUp>
      <FadeUp>
        <Form
          handleModal={handleModal}
          text={formData}
          position={position}
          handleResponse={setResponse}
          handleSuccessRes={setSuccessResponse}
        />
      </FadeUp>

      <FadeUp className="email-text">
        {text.label}
        <a className="ml-2" href={`mailto:${text.email}`}>
          {text.email}
        </a>
      </FadeUp>
      <Modal
        isOpen={modal}
        toggle={handleModal}
        centered
        size="md"
        className="modal-wrapper"
      >
        <ModalHeader toggle={handleModal}></ModalHeader>
        <ModalBody className="response row justify-content-center">
          <Col align="center" xs={12}>
            <img
              className="mb-4"
              src={successResponse ? successIcon : dismissIcon}
              alt="success-icon"
            />
          </Col>
          <Col align="center" xs={12}>
            <p
              className="center"
              dangerouslySetInnerHTML={{ __html: response }}
            />
          </Col>
        </ModalBody>
      </Modal>
    </Col>
  );
};

export default Contact;
