import React, { useRef } from 'react';
import './aiAdvantages.scss';
import FadeUp from '../../../../../components/Animations/FadeUp';
import ResponsiveBox from '../../../../../components/UI/ResponsiveBox';
import { useInViewport } from 'react-in-viewport';

const AiAdvantages = ({ data, ...rest }) => {
  return (
    <ResponsiveBox>
      <div className="ai-advantages" {...rest}>
        <div className="ai-advantages__header">
          <FadeUp>
            <h2
              className="text-medium-32px"
              dangerouslySetInnerHTML={{ __html: data.title }}
            />
          </FadeUp>
          <FadeUp>
            <p className="text-medium-24px">{data.description}</p>
          </FadeUp>
        </div>

        <div className="ai-advantages__list">
          {data.items.map((item, index) => (
            <FadeUp key={index} className="ai-advantages__list__item">
              <AiAdvantageItem item={item} />
            </FadeUp>
          ))}
        </div>
      </div>
    </ResponsiveBox>
  );
};

const AiAdvantageItem = ({ item }) => {
  const itemRef = useRef(null);
  const { inViewport, enterCount } = useInViewport(itemRef);

  return (
    <div className="ai-advantages__list__item__check-container" ref={itemRef}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="ai-advantages__list__item__check-container__checkmark-svg"
      >
        <g clipPath="url(#clip0_10789_17242)">
          <path
            d="M10.8078 0.0326176C9.21875 0.182619 7.44688 0.749807 6.02656 1.57012C3.2375 3.17793 1.16562 5.88262 0.373438 8.93887C0.078125 10.092 0.03125 10.5326 0.03125 11.9998C0.03125 13.1623 0.040625 13.4107 0.129688 13.9217C0.570313 16.4529 1.62969 18.5482 3.37813 20.3623C5.17344 22.2279 7.4375 23.4092 10.0813 23.8732C10.5922 23.9623 10.8406 23.9717 12.0031 23.9717C13.4703 23.9717 13.9109 23.9248 15.0641 23.6295C20.0891 22.3311 23.8109 17.742 23.9891 12.6045C24.0219 11.7092 23.9937 11.5686 23.7219 11.3248C23.3609 11.0014 22.9156 10.9826 22.5078 11.2873C22.2641 11.4701 22.1891 11.6998 22.1562 12.3842C22.0531 14.592 21.2891 16.6404 19.9391 18.3326C19.5781 18.7873 18.7906 19.5748 18.3359 19.9357C16.9438 21.0467 15.2563 21.7826 13.4516 22.0639C12.7156 22.1764 11.2906 22.1764 10.5547 22.0639C8.33281 21.717 6.40625 20.742 4.83125 19.1717C3.26094 17.5967 2.28594 15.6701 1.93906 13.4482C1.82656 12.7123 1.82656 11.2873 1.93906 10.5514C2.28594 8.32949 3.26094 6.40293 4.83125 4.82793C6.67344 2.99043 8.99844 1.96856 11.6187 1.84668C12.3031 1.81387 12.5328 1.73887 12.7156 1.49512C12.8422 1.32168 12.9406 1.07324 12.9406 0.918556C12.9406 0.585743 12.6828 0.182619 12.3922 0.0654316C12.2469 0.00449371 11.3188 -0.0142574 10.8078 0.0326176Z"
            fill="#B1AFF3"
          />
          <path
            className={inViewport && enterCount === 1 ? 'checkmark-path' : ''}
            d="M6 11 L10 15 L22 3"
            stroke="white"
            strokeWidth="1.5"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_10789_17242">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <p className="text-medium-22px">{item}</p>
    </div>
  );
};

export default AiAdvantages;
