import React from 'react';
import { Link } from 'react-router-dom';
import SvgBlueArrowsRight from '../../icons/blueArrow_right';
import ResponsiveBox from '../../UI/ResponsiveBox';
import FadeUp from '../../Animations/FadeUp';

import './companyServices.scss';
import BlockHeader from '../../UI/texts/BlockHeader';

const CompanyServices = ({ text, textColor, ...rest }) => {
  return (
    <div className="services_container" {...rest}>
      <ResponsiveBox>
        <div className="services_content_box">
          <FadeUp
            className="content_title"
            style={{ color: textColor ? textColor : 'black' }}
          >
            <h2
              style={{ color: textColor ? textColor : 'black' }}
              className="text-medium-28px"
            >
              {text.title}
            </h2>
          </FadeUp>
          <div className="content_buttons">
            <FadeUp>
              <Link to={text.fullCycleBtn.link} className="service_button">
                <div className="button_title">
                  <BlockHeader
                    variant="h3"
                    dangerouslySetInnerHTML={{
                      __html: text.fullCycleBtn.title,
                    }}
                  />
                  <SvgBlueArrowsRight />
                </div>
                <p
                  className="button_text"
                  dangerouslySetInnerHTML={{ __html: text.fullCycleBtn.text }}
                />
              </Link>
            </FadeUp>
            <FadeUp>
              <Link
                to={text.teamAugmentationBtn.link}
                className="service_button"
              >
                <div className="button_title">
                  <BlockHeader
                    variant="h3"
                    dangerouslySetInnerHTML={{
                      __html: text.teamAugmentationBtn.title,
                    }}
                  />
                  <SvgBlueArrowsRight />
                </div>
                <p
                  className="button_text"
                  dangerouslySetInnerHTML={{
                    __html: text.teamAugmentationBtn.text,
                  }}
                />
              </Link>
            </FadeUp>
          </div>
        </div>
      </ResponsiveBox>
    </div>
  );
};

export default CompanyServices;
