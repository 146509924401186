import React from 'react';
import { Link } from 'react-router-dom';

import './cases.scss';
import ResponsiveBox from '../../../../../components/UI/ResponsiveBox';
import FadeUp from '../../../../../components/Animations/FadeUp';
import BlockHeader from '../../../../../components/UI/texts/BlockHeader';
import { remoteStorageUrl } from '../../../../../config';
import PlusIcon from '../../../../../components/icons/plusIcon';

const Cases = ({ text, threeBlockLayout = false }) => {
  return (
    <div
      className={`cases_container${
        threeBlockLayout ? ' three-cases_container' : ''
      }`}
    >
      <ResponsiveBox>
        <FadeUp className="cases_title_box">
          <BlockHeader
            dangerouslySetInnerHTML={{
              __html: text.title,
            }}
          />
        </FadeUp>
        <div className="study_cases_box">
          {text.items
            .slice(0, threeBlockLayout ? 3 : text.items.length)
            .map((item) => (
              <FadeUp
                key={item.title}
                className={`study_cases_box_fadeup ${item.className || ''}`}
              >
                <Link
                  to={item.link}
                  className="case_box"
                  style={{ background: item.color }}
                  rel="canonical"
                >
                  <div className="logo_box">
                    {item.backgroundImg && (
                      <img
                        src={`${remoteStorageUrl}/${item.path}/${item.backgroundImg}`}
                        alt={item.title}
                      />
                    )}
                  </div>
                  <div
                    className="text-heavy-24px"
                    style={{
                      color: '#FFFFFF',
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    {item.title}
                  </div>
                  <div
                    className="text-heavy-16px"
                    dangerouslySetInnerHTML={{ __html: item.subtitle }}
                    style={{ color: '#FFFFFF' }}
                  />
                  <div
                    className="text-medium-12px"
                    style={{ color: '#FFFFFF', opacity: 0.7 }}
                  >
                    {item.tech.join(' / ')}
                  </div>

                  <div className="lazyload-wrapper">
                    <img
                      src={`${remoteStorageUrl}/${item.path}/${item.img}`}
                      alt={item.title}
                    />
                  </div>
                </Link>
              </FadeUp>
            ))}
        </div>
        <Link
          to={'/use-cases'}
          className="allCaseBtn"
          style={{ display: 'flex', justifyContent: 'center' }}
          rel="canonical"
        >
          <div className="allCaseBtn_flex">
            <div
              className="text-heavy-16px"
              style={{ color: 'var(--white-text-color)' }}
            >
              All case studies
            </div>
            <div>
              <PlusIcon width="16" height="16" />
            </div>
          </div>
        </Link>
      </ResponsiveBox>
    </div>
  );
};

export default Cases;
