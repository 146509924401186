import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import Wrapper from '../hoc/Wrapper/Wrapper';
import ScrollToTop from '../helpers/scrollToTop';
import NewWorks from '../containers/Works/NewWorks';
import Services from '../containers/Services/Services';
import Android from '../containers/Services/Android/Android';
import LocationBasedApp from '../containers/Services/LocationBasedApp';
import UiUx from '../containers/Services/UiUxDark';
import FullCycle from '../containers/Services/FullCycle/FullCycle';
import Careers from '../containers/Careers/Careers';
import Career from '../containers/Careers/Career/Career';
import PrivacyPolicy, {
  POLICY_TYPES,
} from '../containers/PrivacyPolicy/PrivacyPolicy';
import ContactUs from '../containers/ContactUs/ContactUs';
import Burn from '../containers/Burn/Burn';
import Commit from '../containers/Commit/Commit';
import Shrink from '../containers/Shrink/Shrink';
import Meest from '../containers/Meest/Meest';
import Aob from '../containers/Aob/Aob';
import BigTicket from '../containers/BigTicket/BigTicket';
import Header from './Header/Header';
import text from '../translations/translation.json';
import text2 from '../translations/translation2.json';
import text_wearable_app from '../translations/translation_werable_app.json';
import ar_vr_app from '../translations/translation_AR_VR_app.json';
import sport_translation from '../translations/translation_sports.json';
import iot_translation from '../translations/translation_iot.json';
import healthcare_translation from '../translations/translation_healthcare.json';
import socialNetworks_translation from '../translations/translation_social_networks.json';
import mobileStartups_translation from '../translations/translation_mobile_development_startups.json';
import referral_translation from '../translations/translation_referral.json';
import './App.scss';
import AboutUs from '../containers/AboutUs/AboutUs';
import CrossPlatform from '../containers/Services/CrossPlatform/CrossPlatform';
import NotFound from '../containers/NotFound/notFound';
import { crmData } from '../utils/crmData';
import Eargym from '../containers/Eargym';
import Ios from '../containers/Services/Ios/Ios';
import NewHome from '../containers/NewHome/NewHome';
import FitnessWellness from '../containers/Services/Fitness&Wellness';
import WearableApp from '../containers/Solutions/WearableApp';
import AR_VR from '../containers/Solutions/AR_VR';
import SocialNetworks from '../containers/Solutions/SocialNetworks';
import IOT from '../containers/Solutions/IOT';
import Healthcare from '../containers/Industries/Healthcare';
import TeamAugmentation from '../containers/Services/TeamAugmentation/TeamAugmentation';
import BusinessDigitalization from '../containers/Services/BusinessDigitalization/BusinessDigitalization';
import ECommerce from '../containers/Services/ECommerce';
import MobileDevelopmentStartups from '../containers/Services/MobileDevelopmentStartups/';
import Sports from '../containers/Industries/Sports';
import AlterraCase from './Projects/Alterra';
import RablCase from './Projects/Rabl';
import InstructMeCase from './Projects/InstructMe';
import PratimaCase from './Projects/Pratima';
import TelemedicineCase from './Projects/Telemedicine';
import ParkworksCase from './Projects/Parkworks';
import HigediCase from './Projects/Higedi';
import ReferralPage from '../containers/ReferralPage/ReferralPage';
import AIBasedApp from '../pages/solutions/aiBased/aiBased';
import aiBasedText from '../translations/solutions/aiBase.json';

export const App = () => {
  const [open, setOpen] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const location = useLocation();

  const checkEventTarget = (event) => {
    if (open) {
      const tag = event.target.tagName.toLowerCase();
      !(tag === 'ul' || tag === 'a') && setOpen(false);
    }
  };

  useEffect(() => {
    const _hsq = (window._hsq = window._hsq || []);
    _hsq.push(['setPath', location.pathname]);
    _hsq.push(['trackPageView']);
    _hsq.push([
      'identify',
      {
        contact_id_ga: crmData().clientIdGA,
      },
    ]);
  }, [location]);

  const handleNotFound = (status) => {
    setNotFound(status);
  };
  return (
    <>
      <Header open={open} setOpen={setOpen} notFound={notFound} />
      <div onClick={checkEventTarget} className="App">
        <Wrapper notFound={notFound}>
          <ScrollToTop />
          <Routes>
            <Route
              path="*"
              element={
                <NotFound handleNotFound={(status) => handleNotFound(status)} />
              }
            />
            <Route path="/" element={<NewHome />} />
            <Route path="/services" element={<Services />} />
            <Route
              path="/services/ios"
              element={<Ios data={text.services.ios} />}
            />
            <Route
              path="/services/android"
              element={<Android data={text2.services.android} />}
            />
            <Route
              path="/location-based"
              element={<LocationBasedApp data={text2.services.locationBased} />}
            />
            <Route
              path="/fitness-wellness"
              element={
                <FitnessWellness data={text2.services.fitness_wellness} />
              }
            />
            <Route
              path="/wearable-app"
              element={<WearableApp data={text_wearable_app.text} />}
            />
            <Route path="/ar-vr" element={<AR_VR data={ar_vr_app.text} />} />
            <Route path="/iot" element={<IOT data={iot_translation.text} />} />
            <Route
              path="/ai-based"
              element={<AIBasedApp data={aiBasedText} />}
            />
            <Route
              path="/healthcare"
              element={<Healthcare data={healthcare_translation.text} />}
            />
            <Route
              path="/social-networks"
              element={
                <SocialNetworks data={socialNetworks_translation.text} />
              }
            />
            <Route
              path="/services/ui-ux"
              element={<UiUx data={text2.services.uiux} />}
            />
            <Route
              path="/services/full-cycle"
              element={<FullCycle data={text.services.fullcycle} />}
            />
            <Route
              path="/services/team-augmentation"
              element={<TeamAugmentation data={text.services.augmentation} />}
            />
            <Route
              path="/services/digitalization"
              element={
                <BusinessDigitalization data={text.services.digitalization} />
              }
            />
            <Route
              path="/services/flutter"
              element={<CrossPlatform data={text.services.cross_platform} />}
            />
            <Route
              path="/ecommerce"
              element={<ECommerce data={text2.services.ecommerce} />}
            />
            <Route
              path="/services/startups-mobile-development"
              element={
                <MobileDevelopmentStartups
                  data={mobileStartups_translation.mobile_startups}
                />
              }
            />
            <Route
              path="/sports"
              element={<Sports data={sport_translation.text} />}
            />
            <Route path="/use-cases" element={<NewWorks />} />
            <Route
              path="/privacy-policy"
              element={<PrivacyPolicy policy={POLICY_TYPES.privacy} />}
            />
            <Route
              path="/anti-bribery-policy"
              element={<PrivacyPolicy policy={POLICY_TYPES.anti_bribery} />}
            />
            <Route
              path="/antislavery-policy"
              element={<PrivacyPolicy policy={POLICY_TYPES.antislavery} />}
            />
            <Route
              path="/corporate-policy"
              element={<PrivacyPolicy policy={POLICY_TYPES.corporate} />}
            />
            <Route
              path="/environmental-policy"
              element={<PrivacyPolicy policy={POLICY_TYPES.environmental} />}
            />
            <Route
              path="/equality-policy"
              element={<PrivacyPolicy policy={POLICY_TYPES.equality} />}
            />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/about" element={<AboutUs />} />
            <Route
              path="/use-cases/burn"
              element={<Burn data={text.applications[0]} />}
            />
            <Route
              path="/use-cases/commit"
              element={<Commit data={text.applications[1]} />}
            />
            <Route
              path="/use-cases/shrink"
              element={<Shrink data={text.applications[2]} />}
            />
            <Route
              path="/use-cases/meest"
              element={<Meest data={text.applications[4]} />}
            />
            <Route
              path="/use-cases/aob"
              element={<Aob data={text.applications[5]} />}
            />
            <Route
              path="/use-cases/big-ticket"
              element={<BigTicket data={text.applications[6]} />}
            />
            <Route
              path="/use-cases/eargym"
              element={<Eargym data={text.eargym} />}
            />
            <Route path="/use-cases/alterra" element={<AlterraCase />} />
            <Route
              path="/use-cases/telemedicine"
              element={<TelemedicineCase />}
            />
            <Route path="/use-cases/rabl" element={<RablCase />} />
            <Route path="/use-cases/parkworks" element={<ParkworksCase />} />
            <Route path="/use-cases/instructme" element={<InstructMeCase />} />
            <Route path="/use-cases/pratima" element={<PratimaCase />} />
            <Route path="/use-cases/higedi" element={<HigediCase />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/careers/:career" element={<Career />} />
            <Route
              path="/referral"
              element={<ReferralPage data={referral_translation.referral} />}
            />
          </Routes>
        </Wrapper>
      </div>
    </>
  );
};
