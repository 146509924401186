import React, { useState, useEffect } from 'react';
import NewDevelopment from '../../components/NewDevelopment/NewDevelopment';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import text from '../../translations/translation.json';
import Cases from '../../components/Home/Cases';
import Header from '../../components/Home/Header';
import CompanyServices from '../../components/Shared/CompanyServices';
import Domains from '../../components/Home/Domains';
import Achievements from '../../components/Home/Achievements';

import './home.scss';
import Principles from '../../components/Home/Principles';
import Form from '../../components/Shared/Form';
import FAQ from '../../components/Home/FAQ';

const Home = () => {
  const location = useLocation();
  const [domLoaded, setDomLoaded] = useState(false);

  useEffect(() => {
    setDomLoaded(true);
  }, []);
  return (
    <>
      {domLoaded ? (
        <div className="home ">
          <Helmet>
            <title>{text.metaTags.mainPage.title}</title>
            <meta
              name="description"
              content={text.metaTags.mainPage.description}
            />
            <meta property="og:title" content={text.metaTags.mainPage.title} />
            <meta
              property="og:description"
              content={text.metaTags.mainPage.description}
            />
            <meta name="twitter:creator" content={'Perpetio'} />
            <meta name="twitter:card" content={'webapp'} />
            <meta name="twitter:title" content={text.metaTags.mainPage.title} />
            <meta
              name="twitter:description"
              content={text.metaTags.mainPage.description}
            />
            <link
              rel="canonical"
              href={`${process.env.REACT_APP_SITE_URL}${location.pathname}`}
            />
          </Helmet>
          <Header />
          <NewDevelopment />
          <CompanyServices text={text.homePage_v2_0.company_services} />
          <Cases text={text.homePage_v2_0.cases} />
          <Domains text={text.homePage_v2_0.domains} />
          <Achievements text={text.homePage_v2_0.achivments} />
          <Principles text={text.homePage_v2_0.principles} />
          <Form text={text.homePage_v2_0.form} />
          <FAQ text={text.homePage_v2_0.faq} />
        </div>
      ) : (
        <Helmet>
          <title>{text.metaTags.mainPage.title}</title>
          <meta
            name="description"
            content={text.metaTags.mainPage.description}
          />
          <meta property="og:title" content={text.metaTags.mainPage.title} />
          <meta
            property="og:description"
            content={text.metaTags.mainPage.description}
          />
          <meta name="twitter:creator" content={'Perpetio'} />
          <meta name="twitter:card" content={'webapp'} />
          <meta name="twitter:title" content={text.metaTags.mainPage.title} />
          <meta
            name="twitter:description"
            content={text.metaTags.mainPage.description}
          />
          <link
            rel="canonical"
            href={`${process.env.REACT_APP_SITE_URL}${location.pathname}`}
          />
        </Helmet>
      )}
    </>
  );
};

export default Home;
