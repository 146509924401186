import React from 'react';
import { Row } from 'reactstrap';
import Main from '../../../components/Careers/Career/Main/Main';
import Description from '../../../components/Careers/Career/Description/Description';
import Contact from '../../../components/Careers/Career/Contact/Contact';
import Positions from '../../../components/Careers/Positions/Positions';
import getPageData from '../../../helpers/careerPage';
import { Navigate as Redirect } from 'react-router-dom';
import text from '../../../translations/translation.json';
import withRouter from '../../../utils/withRouter';
import './career.scss';
import { Helmet } from 'react-helmet-async';
import Breadcrumbs from '../../../components/Breadcrumbs/breadcrumbs';

const Career = (props) => {
  const path = props.history.location.pathname.split('/')[2];
  const data = getPageData(path);
  const positions = {
    title: text.careers.career_positions_title,
    items: text.careers.items.items.filter(
      (item) => item.link !== path && item.enabled,
    ),
  };

  return data ? (
    <div className="max-width-container">
      <Helmet>
        <title>
          {data.main.helmetTitle
            ? data.main.helmetTitle
            : 'Currently open positions in Lviv (Ukraine) | Perpetio'}
        </title>
        <meta
          name="description"
          content={
            data.main.helmetDescription
              ? data.main.helmetDescription
              : 'Perpetio is hiring! We are looking for a skilled professionals to join our team in Lviv. Sounds interesting? Check out the requirements and send your CV!'
          }
        />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_SITE_URL}${props.history.pathname}`}
        />
      </Helmet>

      <div className="career">
        <Breadcrumbs />
        <Main text={data.main} />
        <Row className="justify-content-between">
          <Description text={data.description} />
          <Contact text={data.contact} pos={data.main.title} />
          {positions.items.length ? (
            <Positions text={positions} block="career" />
          ) : null}
        </Row>
      </div>
    </div>
  ) : (
    <Redirect to="/careers" />
  );
};
export default withRouter(Career);
