import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import text from '../../translations/translation.json';
import NewMain from '../../components/NewMain/NewMain';
import './contactUs.scss';
import FadeUp from '../../components/Animations/FadeUp';
import ResponsiveBoxHead from '../../components/UI/ResponsiveBoxHead';
import ResponsiveBox from '../../components/UI/ResponsiveBox';
import Form from '../../components/Shared/Form';

const ContactUs = () => {
  const location = useLocation();

  return (
    <div
      style={{
        background:
          'linear-gradient(110deg, #f3f2ff 0%, #e8fffe 62.49%, #f5f6ff 83%)',
        transform: 'rotate(-180deg)',
      }}
    >
      <div
        style={{
          transform: 'rotate(-180deg)',
        }}
      >
        <Helmet>
          <title>Contact Us | Perpetio</title>
          <meta
            name="description"
            content="We are the reliable mobile app development company you were looking for! Boost your business with a slick mobile app from Perpetio."
          />
          <link
            rel="canonical"
            href={`${process.env.REACT_APP_SITE_URL}${location.pathname}`}
          />
        </Helmet>
        <ResponsiveBoxHead className="contactUs-title">
          <NewMain title={text.contactUs.title} text={text.contactUs.text} />
        </ResponsiveBoxHead>
        <div className="contactUs-wrapper">
          <ResponsiveBox topGap={false}>
            <div style={{ padding: 0 }}>
              <FadeUp
                threshold={0.3}
                className={`contactUs-Form-adress-container`}
              >
                <Form text={text.homePage_v2_0.form} />
                <div className="address-container">
                  <div className="address-box">
                    <h4
                      className="address-box-title"
                      dangerouslySetInnerHTML={{
                        __html: text.contactUs.contactEmail.title,
                      }}
                    />
                    <a
                      className="address-box-email"
                      href={`mailto:${text.contactUs.contactEmail.email}`}
                    >
                      {text.contactUs.contactEmail.email}
                    </a>
                  </div>
                  <div className="address-box-office">
                    <div className="address-box">
                      <h4
                        className="address-box-title"
                        dangerouslySetInnerHTML={{
                          __html: text.contactUs.address.title,
                        }}
                      />
                      <p
                        className="address-box-text"
                        dangerouslySetInnerHTML={{
                          __html: text.contactUs.address.text,
                        }}
                      />
                    </div>
                    <div className="address-box">
                      <h4
                        className="address-box-title"
                        dangerouslySetInnerHTML={{
                          __html: text.contactUs.office.title,
                        }}
                      />
                      <p
                        className="address-box-text"
                        dangerouslySetInnerHTML={{
                          __html: text.contactUs.office.text,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </FadeUp>
            </div>
          </ResponsiveBox>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
